import { Link } from "react-router-dom";
import NorthEastOutlinedIcon from "@mui/icons-material/NorthEastOutlined";

const Collections = ({ items, width }) => {
  const renderItem = (item, arrow) => (
    <Link
      to={`/catalog/collections/${item.name}`}
      key={item.id}
      className="hover:text-red-500 aspect-square transition duration-300 ease-in-out lg:hover:-translate-y-1 lg:hover:scale-105"
    >
      <img
        alt=""
        src={item?.cover}
        className="rounded-3xl object-cover w-full h-full"
      />
      <div className="flex gap-1 mt-5 mb-10 uppercase font-medium stroke-2 items-center">
        <p>{item.name}</p>
        {arrow}
      </div>
    </Link>
  );

  return (
    <div className="my-16 lg:px-5">
      <div className="flex flex-col lg:flex-row lg:justify-between gap-2 mb-5 uppercase font-bold w-full">
        <p className="text-4xl">Коллекции</p>
        <div className="flex text-xl stroke-2 items-center gap-1 hover:text-red-500">
          <Link to="/catalog/collections">Посмотреть все</Link>
          <NorthEastOutlinedIcon />
        </div>
      </div>
      {width < 1024 ? (
        <div className="w-full text-2xl sm:text-3xl">
          {items.map((item) => renderItem(item, <NorthEastOutlinedIcon />))}
        </div>
      ) : (
        <div className="w-full">
          <div className="flex text-4xl items-center justify-between gap-5">
            {items
              .slice(0, 2)
              .map((item) =>
                renderItem(
                  item,
                  <NorthEastOutlinedIcon className="h-10 w-10" />
                )
              )}
          </div>
          <div className="flex gap-3.5 text-2xl items-center justify-between">
            {items
              .slice(2)
              .map((item) => renderItem(item, <NorthEastOutlinedIcon />))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Collections;
