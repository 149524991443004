import { useEffect, useState } from "react";
import {
  Outlet,
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
  useLoaderData,
  useNavigation,
} from "react-router-dom";

import "./App.css";

import ErrorBoundary from "./ErrorBoundary";
import Loading from "./Pages/Loading/Loading";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import NotFound from "./Pages/Not Found/NotFound";
import { Policy, policyLoader } from "./Pages/Policy/Policy";
import { Agreement, agreementLoader } from "./Pages/Policy/Agreement";
import { Contract, contractLoader } from "./Pages/Policy/Contract";
import { Info, infoLoader } from "./Pages/Policy/Info";
import { Home, homeLoader } from "./Pages/Home/Home";
import { Catalog, catalogLoader } from "./Pages/Catalog/Catalog";
import { Favorites, favoritesLoader } from "./Pages/Favorites/Favorites";
import { Item, itemLoader } from "./Pages/Item/Item";
import { Cart, cartLoader } from "./Pages/Cart/Cart";
import { News, newsLoader } from "./Pages/News/News";
import { Article, articleLoader } from "./Pages/Article/Article";

import { api } from "./api";

const BasicLayout = ({ cartItems }) => {
  const { navData, contacts } = useLoaderData();
  const navigation = useNavigation();

  return (
    <div>
      {navigation.state === "loading" ? (
        <Loading />
      ) : (
        <>
          <Header navItems={navData} cartSize={cartItems.length} />
          <Outlet
            context={{
              contacts,
            }}
          />
          <Footer navItems={navData} contacts={contacts} />
          <ScrollRestoration />
        </>
      )}
    </div>
  );
};

const App = () => {
  const [favItems, setFavItems] = useState(
    JSON.parse(localStorage.getItem("favItems")) || []
  );
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartItems")) || []
  );

  const getItems = async () => {
    if (cartItems.length) {
      let req = "";
      cartItems.forEach((i, idx) => {
        req += `&filters[id][$in][${idx}]=${i.id}`;
      });
      const res = await api.get(
        `/items?populate[0]=images&populate[1]=size${req}`
      );

      const items = res.data.data.map((item) => {
        const { id, attributes } = item;
        const { size } = attributes;

        const sizeMapping = (id, size, i) =>
          size
            .map((item) => {
              const [size, qty] = Object.entries(item)[0];
              const [_, price] = Object.entries(item)[1];
              return {
                id,
                size: i === "shoes" ? parseFloat(size) : size,
                qty,
                price,
                item: i,
              };
            })
            .filter((item) => item.qty !== 0);

        return {
          id,
          size: size.data.attributes.size.EU
            ? sizeMapping(size.data.id, size.data.attributes.size.EU, "shoes")
            : sizeMapping(
                size.data.id,
                size.data.attributes.size.other,
                "other"
              ),
        };
      });

      const newCartItems = [];

      cartItems.forEach((item) => {
        const itemInCart = items.find((i) => i.id === item.id);
        if (itemInCart) {
          const sizeInCart = itemInCart.size.find(
            (s) => s.size === item.size.size
          );
          if (sizeInCart) {
            if (sizeInCart.qty >= item.qty) {
              newCartItems.push({
                id: item.id,
                qty: item.qty,
                size: sizeInCart,
              });
            }
          }
        }
      });

      setCartItems(newCartItems);
      localStorage.setItem("cartItems", JSON.stringify(newCartItems));
    }
    if (favItems.length) {
      let req = "";
      favItems.forEach((id, idx) => {
        req += `&filters[id][$in][${idx}]=${id}`;
      });
      const res = await api.get(`/items?populate[0]=images${req}`);
      const ids = res.data.data.map((item) => item.id);
      const items = favItems.filter((item) => ids.includes(item.id));
      setFavItems(items);
      localStorage.setItem("favItems", JSON.stringify(items));
    }
  };

  useEffect(() => {
    getItems();
  }, []);

  const handleFav = (e, id) => {
    e.preventDefault();
    let favs = [...favItems];
    const index = favItems.findIndex((i) => i === id);
    if (index >= 0) {
      favs = favs.filter((i, idx) => index !== idx);
    } else {
      favs.push(id);
    }
    setFavItems(favs);
    localStorage.setItem("favItems", JSON.stringify(favs));
  };

  const catalogPaths = [
    { path: "/catalog/newitems", childpath: "/catalog/newitems/item/:id" },
    {
      path: "/catalog/collections",
      childpath: "/catalog/collections/item/:id",
    },
    {
      path: "/catalog/collections/:collection",
      childpath: "/catalog/collections/:collection/item/:id",
    },
    { path: "/catalog/sale", childpath: "/catalog/sale/item/:id" },
    {
      path: "/catalog/brands/:brand",
      childpath: "/catalog/brands/:brand/item/:id",
    },
    {
      path: "/catalog/categories/:category",
      childpath: "/catalog/categories/:category/item/:id",
    },
    {
      path: "/catalog/categories/:category/:brand",
      childpath: "/catalog/categories/:category/:brand/item/:id",
    },
    {
      path: "/catalog/search/:query",
      childpath: "/catalog/search/:query/item/:id",
    },
  ];

  const catalogs = catalogPaths.map((item) => ({
    path: item.path,
    handle: {
      crumb: (data) => [data.crumb],
    },
    loader: async ({ request, params }) => {
      try {
        const newCatalog = request.url.includes("newitems");
        const collectionCatalog = request.url.includes("collections");
        const saleCatalog = request.url.includes("sale");
        const brandsCatalog = request.url.includes("brands");
        const categoryCatalog = request.url.includes("categories");
        const searchCatalog = request.url.includes("search");

        const categories = await api.get("/categories").then((res) =>
          res.data.data.map((category) => ({
            name: category.attributes.name,
            value: category.attributes.value,
          }))
        );

        const crumb = newCatalog
          ? [{ name: "Новинки", url: "/catalog/newitems" }]
          : collectionCatalog && params.collection
          ? [
              { name: "Коллекции", url: "/catalog/collections" },
              {
                name: params.collection,
                url: "/catalog/collections/" + params.collection,
              },
            ]
          : collectionCatalog && !params.collection
          ? [{ name: "Коллекции", url: "/catalog/collections" }]
          : saleCatalog
          ? [{ name: "Распродажа", url: "/catalog/sale" }]
          : brandsCatalog
          ? [
              {
                name: params.brand,
                url: "/catalog/brands/" + params.brand,
              },
            ]
          : categoryCatalog && params.brand
          ? [
              {
                name: categories.find((c) => c.value === params.category).name,
                url: "/catalog/categories/" + params.category,
              },
              {
                name: params.brand,
                url:
                  "/catalog/categories/" + params.category + "/" + params.brand,
              },
            ]
          : categoryCatalog && !params.brand
          ? [
              {
                name: categories.find((c) => c.value === params.category).name,
                url: "/catalog/categories/" + params.category,
              },
            ]
          : searchCatalog
          ? [
              {
                name: `Результаты поиска: ${params.query}`,
                url: `/catalog/search/${params.query}`,
              },
            ]
          : "";

        return { crumb };
      } catch (error) {
        console.log(error);
      }
    },
    children: [
      {
        index: true,
        element: <Catalog favItems={favItems} handleFav={handleFav} />,
        loader: catalogLoader,
      },
      {
        path: item.childpath,
        element: (
          <Item
            favItems={favItems}
            handleFav={handleFav}
            cartItems={cartItems}
            setCartItems={setCartItems}
          />
        ),
        loader: itemLoader,
        handle: {
          crumb: (data) => [data.crumb],
        },
      },
    ],
  }));

  const router = createBrowserRouter([
    {
      path: "/",
      element: <BasicLayout cartItems={cartItems} />,
      loader: async () => {
        try {
          const p1 = api.get("/menus?nested&populate=items&[populate]=*");
          const p2 = api.get("/contact");
          const [r1, r2] = await Promise.all([p1, p2]);
          function mapNavItem(item) {
            const { id, attributes } = item;
            return {
              id,
              title: attributes.title,
              url: attributes.url,
              children: mapNavItems(attributes.children.data),
            };
          }

          function mapNavItems(items) {
            return items.map((item) => mapNavItem(item));
          }

          const navData = mapNavItems(r1.data.data[0].attributes.items.data);
          const contacts = r2.data.data.attributes;

          return { navData, contacts };
        } catch (error) {
          console.log(error);
        }
      },
      children: [
        {
          index: true,
          element: <Home />,
          loader: homeLoader,
        },
        {
          path: "/catalog",
          handle: {
            crumb: () => [{ name: "Каталог", url: "/catalog" }],
          },
          children: [
            {
              index: true,
              element: <Catalog favItems={favItems} handleFav={handleFav} />,
              loader: catalogLoader,
            },
            ...catalogs,
            {
              path: "/catalog/item/:id",
              element: (
                <Item
                  handleFav={handleFav}
                  favItems={favItems}
                  cartItems={cartItems}
                  setCartItems={setCartItems}
                />
              ),
              loader: itemLoader,
              handle: {
                crumb: (data) => [data.crumb],
              },
            },
          ],
        },
        {
          path: "/favorites",
          element: <Favorites handleFav={handleFav} favItems={favItems} />,
          loader: (data) => favoritesLoader(data, favItems),
          handle: {
            crumb: () => [{ name: "Избранное" }],
          },
        },
        {
          path: "/cart",
          element: <Cart cartItems={cartItems} setCartItems={setCartItems} />,
          loader: (data) => cartLoader(data, cartItems),
          handle: {
            crumb: () => [{ name: "Корзина" }],
          },
        },
        {
          path: "/news",
          id: "news",
          loader: newsLoader,
          children: [
            {
              index: true,
              element: <News />,
            },
            {
              path: "/news/article/:id",
              element: <Article />,
              loader: articleLoader,
            },
          ],
        },
        {
          path: "/policy",
          element: <Policy />,
          loader: policyLoader,
        },
        {
          path: "/agreement",
          element: <Agreement />,
          loader: agreementLoader,
        },
        {
          path: "/contract",
          element: <Contract />,
          loader: contractLoader,
        },
        {
          path: "/info",
          element: <Info />,
          loader: infoLoader,
        },
      ],
      errorElement: <ErrorBoundary />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return (
    <div className="h-screen w-screen mx-auto px-1 sm:px-5">
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
