import { Link } from "react-router-dom";
import NorthEastOutlinedIcon from "@mui/icons-material/NorthEastOutlined";

const Content = ({ item, idx, currentIndex, containerWidth, width }) => {
  return (
    <>
      {item.type === "brand" ? (
        <div
          className="absolute bottom-24 lg:bottom-36 px-10 z-10 opacity-0 transition-opacity duration-1000"
          style={{
            color: item.color,
            opacity: currentIndex === idx ? 1 : 0,
            zIndex: currentIndex === idx ? "10" : "0",
            width: width > 768 && containerWidth,
          }}
        >
          <div className="flex flex-col gap-3 items-start sm:flex-row sm:justify-between sm:items-center ">
            <span className="uppercase text-7xl sm:text-8xl md:text-9xl font-medium">
              {item.text}
            </span>
            <Link
              to={`/catalog/brands/${item.text}`}
              className="flex gap-2 items-center bg-gradient-to-r from-[#F5201F] to-[#FF4E16] text-center rounded-3xl px-7 py-3 transition duration-300 ease-in-out lg:hover:-translate-y-1 lg:hover:scale-110"
            >
              <span className="text-white uppercase text-lg font-bold">
                Перейти к бренду
              </span>
              <NorthEastOutlinedIcon />
            </Link>
          </div>
        </div>
      ) : (
        <div
          className="absolute w-full h-full left-10 z-10 opacity-0 transition-opacity duration-1000"
          style={{
            color: item.color,
            opacity: currentIndex === idx ? 1 : 0,
            zIndex: currentIndex === idx ? "10" : "0",
          }}
          key={idx}
        >
          <div className="flex flex-col items-start gap-10">
            <span className="absolute bottom-36 lg:bottom-48 text-3xl min-[500px]:text-4xl md:text-5xl font-medium uppercase w-1/5 sm:w-2/5 lg:w-3/5">
              {item.text}
            </span>
            <Link
              to={
                item.type === "item"
                  ? "/catalog/item/" + item.item
                  : item.type === "collection"
                  ? "/catalog/collections/" + item.text
                  : "/news/article/" + item.news
              }
              className={`flex items-start gap-2 absolute bottom-24 lg:bottom-36 ${
                item.type === "item" ? "text-red-500" : "text-" + item.color
              } hover:text-red-500`}
            >
              <span className="uppercase text-lg font-bold">
                {item.type === "item"
                  ? "Перейти к товару"
                  : item.type === "collection"
                  ? "Перейти в каталог"
                  : "Читать новость"}
              </span>
              <NorthEastOutlinedIcon />
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Content;
