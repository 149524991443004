import Header from "../../Components/CatalogHeader/Header";
import BreadcrumbsComponent from "../../Components/Breadcrumbs/Breadcrumbs";
import GridComponent from "../../Components/Grid/Grid";
import Card from "../../Components/Card/Card";

import useViewport from "../../useViewport";
import { api, url } from "../../api";
import { useLoaderData } from "react-router-dom";
import { useState } from "react";

export const Favorites = ({ handleFav, favItems }) => {
  const { items, getItems } = useLoaderData();

  const { width } = useViewport();

  const [data, setData] = useState(items);
  const [sortType, setSortType] = useState("");

  const handleSort = async (type) => {
    let sorts;
    if (sortType === type) {
      setSortType("");
    } else {
      setSortType(type);
      if (type === "asc" || type === "desc") {
        sorts = `&sort[0]=price:${type}`;
      } else if (type === "new" || type === "popular") {
        sorts = `&sort[0]=${type}:desc`;
      }
    }

    const newData = await getItems(sorts);
    setData(newData);
  };

  const card = (item) => (
    <Card
      item={item}
      handleFav={handleFav}
      favItems={favItems}
      path={`/catalog/item/${item.id}`}
    />
  );

  return (
    <div className="py-8 max-w-[90rem] mx-auto flex flex-col gap-3">
      <Header
        breadcrumbs={<BreadcrumbsComponent />}
        title="Избранное"
        width={width}
        sort={handleSort}
        sortType={sortType}
      />
      {data ? (
        <>
          {width > 1024 ? (
            <GridComponent bottomElems={data} content={(item) => card(item)} />
          ) : (
            <>{data.map((item) => card(item))}</>
          )}
        </>
      ) : (
        <div className="mx-auto text-black/50 min-h-[40rem]">
          Здесь будут ваши избранные товары
        </div>
      )}
    </div>
  );
};

export const favoritesLoader = async (data, favItems) => {
  try {
    if (favItems.length) {
      const getItems = async (sort) => {
        const sorter = sort ? `&${sort}` : "";
        let req = "";
        favItems.forEach((i, idx) => {
          req += `&filters[id][$in][${idx}]=${i}`;
        });
        const res = await api.get(`/items?populate[0]=images${req + sorter}`);
        const items = res.data.data.map((item) => {
          const { id, attributes } = item;
          const { createdAt, updatedAt, publishedAt, images, ...rest } =
            attributes;
          return {
            id,
            images: images.data.map((image) => ({
              id: image.id,
              url: url + image.attributes.url,
            })),
            ...rest,
          };
        });

        return items;
      };
      const items = await getItems();
      return { items, getItems };
    }
    return "";
  } catch (error) {
    console.log(error);
  }
};
