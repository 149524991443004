import { FormControlLabel, Radio } from "@mui/material";
import { grey } from "@mui/material/colors";

const RadioComponent = ({ label, value, disabled, handleChange }) => {
  return (
    <FormControlLabel
      value={value}
      control={
        <Radio
          disabled={disabled}
          className="hover:bg-zinc-50"
          sx={{
            color: grey[900],
            "&.Mui-checked": {
              color: grey[900],
            },
          }}
          onChange={handleChange}
        />
      }
      label={label}
    />
  );
};

export default RadioComponent;
