import { useEffect, useRef, useState } from "react";
import NorthEastOutlinedIcon from "@mui/icons-material/NorthEastOutlined";

import MapComponent from "../../Components/Map/Map";

const Contacts = ({ info, btns }) => {
  const [containerWidth, setContainerWidth] = useState(1280);
  const mapRef = useRef();

  const handleMapLoad = () => {
    if (mapRef.current) {
      setContainerWidth(mapRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    handleMapLoad();
    window.addEventListener("resize", handleMapLoad);
    return () => {
      window.removeEventListener("resize", handleMapLoad);
    };
  }, []);

  return (
    <div className="my-16 lg:px-5">
      <p className="uppercase font-bold text-4xl mb-8 w-full">Контакты</p>
      <div className="flex flex-col lg:flex-row gap-5 w-full h-full justify-between">
        <div className="flex flex-col lg:w-1/2 gap-3.5 justify-between">
          {info.map((item) => (
            <div
              className="bg-[#F9F9F9] rounded-3xl flex flex-col justify-center pl-5 py-2 lg:h-1/5"
              key={item.label}
            >
              <p className="uppercase text-lg font-medium opacity-40">
                {item.label}
              </p>
              <p className="text-2xl lg:text-4xl">{item.data}</p>
            </div>
          ))}
          <div className="flex gap-3 w-full lg:h-1/5">
            {btns.map((btn) => (
              <a
                key={btn.label}
                href={btn.href}
                className={`w-1/2 py-7 mx-2 lg:mx-0 lg:py-0 rounded-3xl bg-gradient-to-r ${
                  btn.label === "WhatsApp"
                    ? "from-[#16C231] to-[#55F170]"
                    : "from-[#1D65EA] to-[#528DFA]"
                } text-white uppercase font-medium text-2xl sm:text-3xl md:text-4xl lg:text-3xl xl:text-4xl flex justify-center items-center transition duration-300 ease-in-out lg:hover:-translate-y-1 lg:hover:scale-105`}
              >
                <span>{btn.label}</span>
                <NorthEastOutlinedIcon className="w-6 h-6 sm:h-8 sm:w-8 md:h-10 md:w-10" />
              </a>
            ))}
          </div>
        </div>
        <div className="overflow-x-hidden rounded-3xl lg:w-1/2" ref={mapRef}>
          <MapComponent containerWidth={containerWidth} />
        </div>
      </div>
    </div>
  );
};

export default Contacts;
