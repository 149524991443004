import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import { Helmet } from "react-helmet";

import BreadcrumbsComponent from "../../Components/Breadcrumbs/Breadcrumbs";
import Carousel from "./Carousel";
import Sizes from "./Sizes";
import Description from "./Description";
import Recommendations from "./Recommendations";

import useViewport from "../../useViewport";
import { api, url } from "../../api";

export const Item = ({ handleFav, favItems, cartItems, setCartItems }) => {
  const { item, recommendations, info } = useLoaderData();
  const [checkedSize, setCheckedSize] = useState({});
  const [price, setPrice] = useState(item.price);
  const [oldPrice, setOldPrice] = useState(item.old_price);
  const [cartBtnDisabled, setCartBtnDisabled] = useState(false);

  const { width } = useViewport();

  const isAnySizeAvailable = (data) => {
    return data.some((item) => {
      return Object.keys(item).some(
        (key) => key !== "price" && key !== "old_price" && item[key] > 0
      );
    });
  };

  useEffect(() => {
    setCartBtnDisabled(!isAnySizeAvailable(item.size || item.size));
  }, [item.size]);

  const handleSizeCheck = (size) => {
    if (checkedSize === size) {
      setCheckedSize({});
      setPrice(item.price);
      setOldPrice(item.old_price);
    } else {
      const itemInCart = cartItems.find(
        (i) => i.id === item.id && i.size.size === size.size
      );
      if (itemInCart) {
        if (itemInCart.qty >= itemInCart.size.qty) {
          setCartBtnDisabled(true);
        } else {
          setCartBtnDisabled(false);
        }
      } else {
        setCartBtnDisabled(false);
      }
      setCheckedSize(size);
      setPrice(size.price);
      setOldPrice(size.oldPrice);
    }
  };

  const handleAddToCart = () => {
    if (checkedSize.size) {
      let items = [...cartItems];

      const itemInCart = items.find(
        (i) => i.id === item.id && i.size.size === checkedSize.size
      );

      if (itemInCart) {
        items.forEach((i) => {
          if (i === itemInCart) {
            i.qty += 1;
          }
        });
      } else {
        items.push({
          id: item.id,
          size: checkedSize,
          qty: 1,
        });
      }

      setCheckedSize({});
      setCartItems(items);
      localStorage.setItem("cartItems", JSON.stringify(items));
    }
  };

  return (
    <div className="py-8 max-w-[90rem] mx-auto">
      <Helmet>
        <title>{item.name}</title>
        <meta name="description" content={item.description} />
      </Helmet>
      {width < 1024 && (
        <>
          <BreadcrumbsComponent />
          <p className="text-2xl sm:text-4xl uppercase font-bold mt-5">
            {item.name}
          </p>
          <div className="flex gap-3.5">
            {item.sale && (
              <p className="relative text-xl sm:text-2xl font-medium text-zinc-400">
                {oldPrice.toLocaleString("ru-RU")} ₽
                <span className="absolute top-1/2 left-0 h-1 bg-zinc-400 rotate-[5deg] w-20 sm:w-24" />
              </p>
            )}
            <p className="text-xl sm:text-2xl font-medium">
              {price.toLocaleString("ru-RU")} ₽
            </p>
          </div>
        </>
      )}
      <div className="flex flex-col lg:flex-row gap-10 mt-5">
        <Carousel
          images={item.images}
          item={item}
          favItems={favItems}
          handleFav={handleFav}
          width={width}
        />
        <div className="flex flex-col gap-5 w-full">
          {width > 1024 && (
            <>
              <BreadcrumbsComponent />
              <p className="text-4xl uppercase font-bold">{item.name}</p>
              <div className="flex gap-3.5">
                {item.sale && (
                  <p className="relative text-2xl font-medium text-zinc-400">
                    {oldPrice.toLocaleString("ru-RU")} ₽
                    <span className="absolute top-1/2 left-0 h-1 bg-zinc-400 rotate-[5deg] w-24" />
                  </p>
                )}
                <p className="text-2xl font-medium">
                  {price.toLocaleString("ru-RU")} ₽
                </p>
              </div>
            </>
          )}
          <Sizes
            sizes={item.size}
            width={width}
            handleSizeCheck={handleSizeCheck}
            checkedSize={checkedSize}
            sizeChart={item?.sizeChart}
          />
          <div className="flex flex-col sm:flex-row sm:justify-center lg:justify-normal gap-3">
            {!cartBtnDisabled && (
              <button
                onClick={handleAddToCart}
                className={
                  checkedSize.size
                    ? "bg-gradient-to-r from-[#F5201F] to-[#FF4E16] text-white transition duration-300 ease-in-out lg:hover:-translate-y-1 lg:hover:scale-110 stroke-2 text-center rounded-3xl px-5 xl:px-6 py-5 lg:py-3 cursor-pointer"
                    : "bg-[#F9F9F9] text-center text-black/50 rounded-3xl px-5 xl:px-6 py-5 lg:py-3 cursor-not-allowed"
                }
              >
                <span className="uppercase text-lg font-bold">
                  Добавить в корзину
                </span>
              </button>
            )}
            <button
              onClick={(e) => handleFav(e, item.id)}
              className="bg-[#F9F9F9] text-center rounded-3xl px-5 xl:px-6 py-5 lg:py-2 flex gap-1 items-center justify-center transition duration-300 ease-in-out lg:hover:-translate-y-1 lg:hover:scale-110 stroke-2"
              style={{
                display:
                  favItems?.findIndex((i) => i === item.id) < 0
                    ? "flex"
                    : "none",
              }}
            >
              <span className="text-black uppercase text-lg font-bold">
                В избранное
              </span>
              <StarIcon />
            </button>
          </div>
          <Description description={item.description} info={info} />
        </div>
      </div>
      <Recommendations
        items={recommendations}
        handleFav={handleFav}
        favItems={favItems}
      />
    </div>
  );
};

export const itemLoader = async ({ params }) => {
  try {
    const res = await api.get(
      `/items/${params.id}?populate[0]=images&populate[1]=brands.size_chart&populate[2]=size&populate[3]=colors`
    );
    const { id, attributes } = res.data.data;
    const {
      name,
      price,
      description,
      images,
      size,
      brands,
      colors,
      sale,
      old_price,
    } = attributes;

    const sizeMapping = (id, size, i) =>
      size
        .map((item) => {
          const [size, qty] = Object.entries(item)[0];
          const [_, price] = Object.entries(item)[1];
          return {
            id,
            size: i === "shoes" ? parseFloat(size) : size,
            qty,
            price,
            item: i,
          };
        })
        .filter((item) => item.qty !== 0);

    const item = {
      id,
      name,
      price,
      description,
      sale,
      old_price,
      size: size.data.attributes.size.EU
        ? sizeMapping(size.data.id, size.data.attributes.size.EU, "shoes")
        : sizeMapping(size.data.id, size.data.attributes.size.other, "other"),
      images: images.data.map((image) => ({
        id: image.id,
        url: url + image.attributes.url,
      })),
      new: attributes.new,
      sizeChart: brands.data[0].attributes.size_chart.data?.attributes.url
        ? url + brands.data[0].attributes.size_chart.data?.attributes.url
        : "",
    };

    const recRes = await api.get(
      `/items?pagination[limit]=9&populate[0]=images&filters[$or][0][brands][name][$eq]=${brands.data[0].attributes.name}&filters[$or][1][colors][label]=${colors.data[0].attributes.label}&filters[id][$ne]=${id}`
    );

    const recommendations = recRes.data.data.map((i) => {
      const { id, attributes } = i;
      const { images, name, old_price, price, sale } = attributes;
      return {
        id,
        name,
        price,
        sale,
        old_price,
        new: attributes.new,
        images: images.data.map((image) => ({
          id: image.id,
          url: url + image.attributes.url,
        })),
      };
    });

    const infoRes = await api.get("/item-info");
    const info = infoRes.data.data.attributes;

    const crumb = { name: item.name };

    return { item, crumb, recommendations, info };
  } catch (error) {
    console.log(error);
  }
};
