import { Link } from "react-router-dom";

const Item = ({ item, arrow }) => {
  return (
    <Link
      to={`/news/article/${item.id}`}
      className="hover:text-red-500 mb-10 sm:mb-10 lg:mb-0 min-w-full flex flex-col text-left gap-3 sm:gap-5 transition duration-300 ease-in-out lg:hover:-translate-y-1 lg:hover:scale-105"
    >
      <img alt="" src={item.cover} className="min-w-full rounded-3xl" />
      <p>
        {item.title} {arrow}
      </p>
    </Link>
  );
};

export default Item;
