import { Link } from "react-router-dom";
import NorthEastOutlinedIcon from "@mui/icons-material/NorthEastOutlined";

import ScrollContainer from "../../Components/ScrollContainer/ScrollContainer";

const New = ({ items }) => {
  return (
    <div className="my-16 lg:px-5">
      <div className="flex flex-col gap-2 lg:flex-row lg:justify-between uppercase font-bold">
        <p className="text-4xl">Новые поступления</p>
        <div className="flex text-xl items-center gap-1 hover:text-red-500">
          <Link to="/catalog/newitems">Посмотреть все</Link>
          <NorthEastOutlinedIcon />
        </div>
      </div>
      <ScrollContainer
        content={items.map((item) => (
          <Link
            to={`catalog/newitems/item/${item.id}`}
            className="flex flex-col gap-3.5 min-w-full w-full sm:min-w-[80%] sm:w-[80%] md:min-w-[60%] md:w-[60%] lg:min-w-[30%] lg:w-[30%] pb-10 text-sm text-left hover:text-red-500 transition duration-300 ease-in-out hover:-translate-y-1 hover:scale-105"
            key={item.id}
          >
            <div className="aspect-square flex items-center justify-center pl-5 pb-5 min-w-full rounded-3xl bg-gradient-to-r from-[#F5201F] to-[#FF4E16]">
              <img src={item.image} alt="" className="min-w-full rotate-45" />
            </div>
            <div className="flex flex-col gap-3.5">
              <p>{item.name}</p>
              <div className="flex gap-3.5">
                {item.sale && (
                  <p className="relative text-sm text-zinc-400">
                    {item.old_price.toLocaleString("ru-RU")} ₽
                    <span className="absolute top-1/2 left-0 h-1 bg-zinc-400 rotate-[5deg] w-14" />
                  </p>
                )}
                <p className="font-bold">
                  {item.price.toLocaleString("ru-RU")} ₽
                </p>
              </div>
            </div>
          </Link>
        ))}
      />
    </div>
  );
};

export default New;
