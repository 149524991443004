import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { grey } from "@mui/material/colors";

const CheckboxComponent = ({ content, filter, handleChecked }) => {
  return (
    <FormControlLabel
      key={content?.id}
      className="flex"
      control={
        <Checkbox
          sx={{
            color: grey[900],
            "&.Mui-checked": {
              color: grey[900],
            },
          }}
          className="py-1 hover:bg-zinc-50"
          onChange={() =>
            handleChecked(`&filters${filter}[$eq]=${content?.name}`)
          }
        />
      }
      label={content?.name}
    />
  );
};

export default CheckboxComponent;
