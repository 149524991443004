import React, { useState, useEffect, useRef } from "react";

import Content from "./Content";
import { useSwipeable } from "react-swipeable";

const Carousel = ({ data, width }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageHeight, setImageHeight] = useState("46rem");
  const [containerWidth, setContainerWidth] = useState("46rem");

  const imageRef = useRef();
  const containerRef = useRef(null);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      const idx = currentIndex;
      if (idx < data.length - 1) {
        setCurrentIndex(idx + 1);
      } else {
        setCurrentIndex(0);
      }
    },
    onSwipedRight: () => {
      const idx = currentIndex;
      if (idx === 0) {
        setCurrentIndex(data.length - 1);
      } else {
        setCurrentIndex(idx - 1);
      }
    },
  });

  const slides = Array.from({ length: data.length }, (_, index) => index);

  const handleImageLoad = () => {
    if (imageRef.current) {
      setImageHeight(imageRef.current.offsetHeight);
    }
  };

  const handleContainerLoad = () => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    const carouselInfiniteScroll = () => {
      if (currentIndex === data.length - 1) {
        setCurrentIndex(0);
      } else setCurrentIndex((prevIdx) => prevIdx + 1);
    };

    const interval = setInterval(() => {
      carouselInfiniteScroll();
    }, 6000);

    window.addEventListener("resize", handleImageLoad);
    window.addEventListener("resize", handleContainerLoad);
    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", handleImageLoad);
      window.removeEventListener("resize", handleContainerLoad);
    };
  }, [currentIndex, data.length]);

  const handleSlide = (idx) => {
    setCurrentIndex(idx);
  };

  return (
    <div
      className="relative rounded-3xl overflow-hidden"
      ref={containerRef}
      onLoad={handleContainerLoad}
    >
      <div
        className="relative w-[90rem] xl:w-full"
        style={{ height: imageHeight }}
        {...handlers}
      >
        {data.map((item, idx) => (
          <div key={item.id}>
            <Content
              item={item}
              idx={idx}
              currentIndex={currentIndex}
              containerWidth={containerWidth}
              width={width}
            />
            <img
              alt="carousel"
              src={item.image}
              ref={imageRef}
              onLoad={handleImageLoad}
              className="absolute min-w-full right-1/2 md:right-0 opacity-0 transition-opacity duration-1000"
              style={{
                opacity: currentIndex === idx ? 1 : 0,
              }}
            />
          </div>
        ))}
      </div>
      <div className="absolute bottom-10 px-10 w-full flex gap-1 z-10">
        {slides.map((slide) => (
          <button
            key={slide}
            className={`${
              currentIndex === slide ? "bg-white" : "bg-white/50"
            } h-1 rounded-3xl`}
            style={{ width: `${100 / slides.length}%` }}
            onClick={() => handleSlide(slide)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
