import { Link } from "react-router-dom";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";

const MenuSM = ({
  items,
  toggleDrawer,
  width,
  showSubmenu,
  showSubsubMenu,
  submenuOpen,
  subsubmenuOpen,
  setMenuOpen,
}) => {
  return (
    <div
      className="relative"
      style={{
        width: width < 640 ? (5 / 6) * width : (1 / 2) * width,
      }}
    >
      <button onClick={(e) => toggleDrawer(e, false)} className="m-5 absolute">
        <CloseOutlinedIcon />
      </button>
      <div className="flex flex-col text-right mt-5 w-full">
        {items.map((item) => (
          <div
            key={item.id}
            className="px-5 py-3"
            style={{
              backgroundColor: submenuOpen === item.title && "#F9F9F9",
            }}
          >
            {item.children.length > 0 ? (
              <div>
                <Link
                  to={item.url}
                  className="hover:text-red-500 uppercase"
                  onClick={(e) => showSubmenu(e, item.title)}
                >
                  <KeyboardArrowDownOutlinedIcon />
                  {item.title}
                </Link>
                <div
                  className="relative overflow-hidden"
                  style={{
                    height: submenuOpen === item.title ? "100%" : "0",
                    overflow: submenuOpen === item.title ? "visible" : "hidden",
                  }}
                >
                  <div className="flex flex-col whitespace-nowrap">
                    {item.children.map((child) => (
                      <div key={child.id}>
                        {child.children.length > 0 ? (
                          <div>
                            <Link
                              to={child.url}
                              className="hover:text-red-500"
                              onClick={(e) => showSubsubMenu(e, child.title)}
                            >
                              <KeyboardArrowLeftOutlinedIcon />
                              {child.title}
                            </Link>
                            <div
                              className="flex flex-col absolute left-0 top-0 text-left"
                              style={{
                                visibility:
                                  subsubmenuOpen === child.title
                                    ? "visible"
                                    : "hidden",
                                backgroundColor:
                                  subsubmenuOpen === child.title && "#F9F9F9",
                              }}
                            >
                              {child.children.map((sub) => (
                                <Link
                                  to={sub.url}
                                  className="hover:text-red-500"
                                  key={sub.id}
                                  onClick={() => setMenuOpen(false)}
                                >
                                  {sub.title}
                                </Link>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <Link
                            to={child.url}
                            className="hover:text-red-500"
                            onClick={() => setMenuOpen(false)}
                          >
                            {child.title}
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <Link
                to={item.url}
                className="hover:text-red-500 uppercase"
                onClick={() => setMenuOpen(false)}
              >
                {item.title}
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuSM;
