import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

import car from "../../Icons/car.svg";

import { api } from "../../api";

const Search = ({ toggleDrawer, width }) => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  const navigate = useNavigate();

  const handleInputChange = async (event) => {
    try {
      const inputValue = event.target.value;
      setQuery(inputValue);

      if (!inputValue) {
        setResults([]);
      } else {
        const res = await api.get(
          `/items?pagination[limit]=5&filters[name][$contains]=${inputValue}`
        );
        const items = res.data.data.map((item) => {
          const { id, attributes } = item;
          return {
            id,
            name: attributes.name,
          };
        });

        setResults(items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePressEnter = (e) => {
    if (e.key === "Enter") {
      toggleDrawer(e, false);
      clearInput();
      navigate(`/catalog/search/${query}`);
    }
  };

  const clearInput = () => {
    setQuery("");
    setResults([]);
  };

  return (
    <>
      <div className="flex w-full px-5 sm:px-16 py-2 justify-between items-center">
        {width > 414 && (
          <Link to="/" className="flex gap-3 py-5">
            <img src={car} alt="" />
            {width > 1024 && (
              <span className="text-3xl font-bold">HYPESTATION</span>
            )}
          </Link>
        )}
        <div className="bg-[#F5F5F5] rounded-3xl py-2 px-3 flex sm:w-1/2">
          <SearchOutlinedIcon />
          <input
            type="text"
            value={query}
            onChange={handleInputChange}
            className="bg-transparent w-full focus:outline-none px-3"
            onKeyDown={handlePressEnter}
          />
          <ClearOutlinedIcon
            onClick={clearInput}
            className="hover:cursor-pointer hover:text-red-500"
            style={{
              visibility: query ? "visible" : "hidden",
            }}
          />
        </div>
        <button
          onClick={(e) => toggleDrawer(e, false)}
          className="hover:text-red-500"
        >
          {width > 1024 ? "Закрыть" : <ClearOutlinedIcon />}
        </button>
      </div>
      <div className="flex items-center justify-center mb-10">
        <div className="flex flex-col gap-1">
          <p className="text-sm opacity-40">Популярные запросы</p>
          {results.map((item) => (
            <Link
              to={`/catalog/item/${item.id}`}
              className="hover:text-red-500"
              onClick={(e) => toggleDrawer(e, false)}
            >
              {item.name}
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default Search;
