import { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";

import Tinkoff from "react-tinkoff-pay";

import BreadcrumbsComponent from "../../Components/Breadcrumbs/Breadcrumbs";
import Items from "./Items";
import Order from "./Order";

import useViewport from "../../useViewport";
import { api, url } from "../../api";
import { Alert, Modal, Snackbar } from "@mui/material";

import car from "../../Icons/car.svg";

export const Cart = ({ cartItems, setCartItems }) => {
  const initialValues = {
    name: "",
    phone: "",
    email: "",
    city: "",
    street: "",
    aptNum: "",
    apt: "",
  };

  const { items, total } = useLoaderData();
  const [checkedItems, setCheckedItems] = useState([]);
  const [formData, setFormData] = useState(initialValues);
  const [delivery, setDelivery] = useState("");
  const [payment, setPayment] = useState("");
  const [destination, setDestination] = useState("");
  const [form, setForm] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [snakbarOpen, setSnackbarOpen] = useState(false);
  const [orderId, setOrderId] = useState();

  const { width } = useViewport();

  const navigate = useNavigate();

  const qtyChange = (plus, item) => {
    const { id, size, qty } = item;
    const cartItem = cartItems.find(
      (i) => i.id === id && i.size.size === size.size
    );
    const newQty = qty + plus;
    let newCartItems = [...cartItems];
    if (newQty) {
      newCartItems = newCartItems.map((i) => {
        if (i === cartItem) {
          i.qty = newQty;
        }
        return i;
      });
    } else {
      newCartItems = newCartItems.filter((i) => i !== cartItem);
    }
    setCartItems(newCartItems);
    localStorage.setItem("cartItems", JSON.stringify(newCartItems));
  };

  const handleCheck = (item) => {
    const { id, size, qty } = item;
    const cartItem = checkedItems.find(
      (i) => i.id === id && i.size.size === size.size
    );
    if (cartItem) {
      setCheckedItems(checkedItems.filter((i) => i !== cartItem));
    } else {
      setCheckedItems([...checkedItems, { id, size, qty }]);
    }
  };

  const handleDelete = () => {
    const newCartItems = [...cartItems];
    const filteredArray = newCartItems.filter((item2) => {
      return !checkedItems.some(
        (item1) =>
          item1.id === item2.id &&
          item1.size === item2.size &&
          item1.qty === item2.qty
      );
    });

    setCartItems(filteredArray);
    localStorage.setItem("cartItems", JSON.stringify(filteredArray));
    setCheckedItems([]);
  };

  const handleFormDataChange = (label, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [label]: value,
    }));
  };

  const handleMoscow = () => {
    setDestination("moscow");
    handleFormDataChange("city", "Москва");
  };

  const postData = async (name, phone, email, city, street, aptNum, apt) => {
    const res = await api.post("/orders", {
      data: {
        name,
        phone,
        email,
        address: city + " " + street + " " + aptNum + " " + apt,
        delivery,
        payment,
        items: items.map((i) => i.id),
      },
    });
    return res.data.data.id;
  };

  const openPayForm = (email, phone, id) => {
    setForm({
      email,
      phone,
      terminalkey: "1705591432022",
      frame: "false",
      language: "ru",
      amount: total.toString(),
      order: id,
      description: "",
      name: "",
    });
  };

  const clearCartData = () => {
    setFormData(initialValues);
    setCheckedItems([]);
    setDelivery("");
    setPayment("");
    setDestination();
    setCartItems([]);
    localStorage.removeItem("cartItems");
  };

  const putNewItems = async (id) => {
    const { arr3, arr4 } = updateQty();
    const promise = [];
    arr3.forEach((item) => {
      promise.push(
        api.put(`/sizes/${item.id}`, {
          data: {
            size: item.sizes,
          },
        })
      );
    });
    promise.push(
      api.put(`/orders/${id}`, {
        data: {
          sizes: {
            sizes: arr4,
          },
        },
      })
    );

    await Promise.all(promise);
  };

  const updateQty = () => {
    const arr1 = [...cartItems];
    const arr2 = [...items];
    const arr3 = [];
    const arr4 = [];
    arr1.forEach((item) => {
      const product = arr2.find((p) => p.id === item.id);
      const { size: itemSize } = item.size;
      const { qty } = item;
      Object.keys(product.sizes).forEach((key) => {
        const sizeArray = product.sizes[key];
        sizeArray.forEach((sizeObj) => {
          if (sizeObj.hasOwnProperty(itemSize)) {
            sizeObj[itemSize] = sizeObj[itemSize] - qty;
          }
        });
        arr3.push({ id: item.size.id, sizes: { [key]: sizeArray } });
      });
      arr4.push({
        item: product.name,
        size: item.size.size,
        qty,
      });
    });
    return { arr3, arr4 };
  };

  const handleOrder = async () => {
    const { name, phone, email, city, street, aptNum, apt } = formData;

    if (delivery && payment && name && phone && email) {
      const id = await postData(name, phone, email, city, street, aptNum, apt);

      await putNewItems(id);

      setOrderId(id);

      if (payment === "Картой") {
        openPayForm(email, phone, id);
      } else {
        setModalOpen(true);
      }

      clearCartData();
    } else {
      setSnackbarOpen(true);
    }
  };

  return (
    <div className="py-8 max-w-[90rem] mx-auto min-h-[55rem]">
      <BreadcrumbsComponent />
      <p className="text-4xl font-bold uppercase mt-5">Корзина</p>
      {items ? (
        <div className="mt-5 flex flex-col gap-5 lg:flex-row xl:gap-16">
          <Items
            items={items}
            width={width}
            qtyChange={qtyChange}
            handleCheck={handleCheck}
            handleDelete={handleDelete}
            checkedItems={checkedItems}
          />
          <Order
            total={total}
            delivery={delivery}
            setDelivery={setDelivery}
            payment={payment}
            setPayment={setPayment}
            formData={formData}
            handleFormDataChange={handleFormDataChange}
            handleOrder={handleOrder}
            destination={destination}
            setDestination={setDestination}
            handleMoscow={handleMoscow}
          />
          {form && <Tinkoff.Pay form={form} />}
        </div>
      ) : (
        <div className="text-black/50 py-5">
          Здесь будут товары, добавленные в корзину
        </div>
      )}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div
          style={{
            transform: "translate(-50%, -50%)",
          }}
          className="absolute top-1/2 left-1/2 h-1/2 lg:h-1/4 w-full lg:w-1/2 bg-[#F9F9F9] border-2 border-black flex flex-col items-center justify-center gap-3"
        >
          <div className="flex gap-3 mb-5">
            <img src={car} alt="" />
            <span className="text-3xl font-bold">HYPESTATION</span>
          </div>
          <p>Номер вашего заказа: {orderId}</p>
          <p className="">Благодарим вас за выбор нашего магазина</p>
          <p className="w-[95%] lg:w-2/3 text-center">
            В ближайшее время с вами свяжется наш менеджер для подтверждения
            доставки
          </p>
          <button
            onClick={() => navigate("/")}
            className="text-white mt-5 bg-gradient-to-r from-[#F5201F] to-[#FF4E16] rounded-3xl px-10 py-3 transition duration-300 ease-in-out lg:hover:-translate-y-1 lg:hover:scale-110"
          >
            На главную
          </button>
        </div>
      </Modal>
      <Snackbar
        open={snakbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="warning"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Пожалуйста, заполните все поля корректно
        </Alert>
      </Snackbar>
    </div>
  );
};

export const cartLoader = async (data, cartItems) => {
  try {
    if (cartItems.length) {
      let req = "";
      cartItems.forEach((i, idx) => {
        req += `&filters[id][$in][${idx}]=${i.id}`;
      });
      const res = await api.get(
        `/items?populate[0]=images${req}&populate[1]=size`
      );
      const resultArray = res.data.data.map((item) => {
        const { id, attributes } = item;
        const { createdAt, updatedAt, publishedAt, images, size, ...rest } =
          attributes;
        return {
          id,
          sizes: size.data.attributes.size,
          image: url + images.data[0].attributes.url,
          ...rest,
        };
      });

      const total = cartItems.reduce((acc, item) => {
        const itemPrice = item.size.price || 0;
        return acc + itemPrice * item.qty;
      }, 0);

      const items = cartItems.map(({ id, size, qty }) => {
        const itemFromSecondArray = resultArray.find((item) => item.id === id);
        return {
          size,
          qty,
          ...itemFromSecondArray,
        };
      });

      return { items, total };
    }

    return "";
  } catch (error) {
    console.log(error);
  }
};
