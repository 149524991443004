import { useState } from "react";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";

const Header = ({ filterBtn, breadcrumbs, title, sort, sortType, width }) => {
  const [sortOpen, setSortOpen] = useState(false);

  const showSort = () => {
    setSortOpen(!sortOpen);
  };

  return (
    <div>
      {breadcrumbs}
      <div className="flex flex-col py-4 sm:flex-row sm:justify-between">
        <p className="font-bold text-4xl uppercase mb-3">{title}</p>
        <div
          className={
            filterBtn
              ? "flex items-center justify-between sm:gap-5"
              : "flex justify-end items-center"
          }
        >
          {filterBtn}
          <div className="relative sort z-20">
            <button className="hover:text-red-500" onClick={showSort}>
              Сортировка <ExpandLessOutlinedIcon />
            </button>
            <div
              className="text-sm absolute top-5 -left-20 px-5 py-4 bg-white rounded-3xl flex flex-col items-end w-52 z-10 sort-menu"
              style={
                width < 1024
                  ? {
                      visibility: sortOpen ? "visible" : "hidden",
                      opacity: sortOpen ? "1" : "0",
                    }
                  : {}
              }
            >
              <button
                className="opacity-40 hover:opacity-100"
                style={{
                  opacity: sortType === "asc" ? "1" : "",
                }}
                onClick={() => sort("asc")}
              >
                Цена по возрастанию
              </button>
              <button
                className="opacity-40 hover:opacity-100"
                onClick={() => sort("desc")}
                style={{
                  opacity: sortType === "desc" ? "1" : "",
                }}
              >
                Цена по убыванию
              </button>
              <button
                className="opacity-40 hover:opacity-100"
                onClick={() => sort("popular")}
                style={{
                  opacity: sortType === "popular" ? "1" : "",
                }}
              >
                Популярное
              </button>
              <button
                className="opacity-40 hover:opacity-100"
                onClick={() => sort("new")}
                style={{
                  opacity: sortType === "new" ? "1" : "",
                }}
              >
                Новое
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
