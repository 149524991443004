import { Link } from "react-router-dom";

import Navigation from "./Navigation";

import car from "../../Icons/car.svg";
import TelegramIcon from "../../Icons/Telegram";
import WhatsappIcon from "../../Icons/Whatsapp";

const Footer = ({ navItems, contacts }) => {
  const { tel, address, email, whatsapp, telegram } = contacts;

  return (
    <footer className="bg-[#1F1F1F] rounded-3xl px-10 py-6 text-white">
      <div className="flex flex-col md:flex-row md:justify-between gap-5 lg:mb-3">
        <div className="flex flex-col gap-5">
          <div>
            <Link to="/" className="flex gap-3">
              <img src={car} alt="" />
              <span className="text-3xl font-bold text-white">HYPESTATION</span>
            </Link>
          </div>
          <p className="text-sm">{address}</p>
          <p className="text-sm">{tel}</p>
          <p className="text-sm">{email}</p>
          <div className="flex gap-4">
            <a href={`https://t.me/${telegram}`}>
              <TelegramIcon className="w-6 h-6 fill-white hover:fill-red-500" />
            </a>
            <a href={`//api.whatsapp.com/send?phone=${whatsapp}`}>
              <WhatsappIcon className="w-6 h-6 fill-white hover:fill-red-500" />
            </a>
          </div>
        </div>
        <Navigation items={navItems} />
      </div>
      <div className="flex flex-col lg:flex-row gap-3.5">
        <Link to="/policy" className="text-xs opacity-40 hover:opacity-100">
          Политика конфиденциальности
        </Link>
        <Link to="/agreement" className="text-xs opacity-40 hover:opacity-100">
          Пользовательское соглашение
        </Link>
        <Link to="/contract" className="text-xs opacity-40 hover:opacity-100">
          Договор оферты
        </Link>
        <Link to="/info" className="text-xs opacity-40 hover:opacity-100">
          Оплата, доставка, возврат
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
