import { Link } from "react-router-dom";

import { IconButton } from "@mui/material";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import RemoveCircleOutlineSharpIcon from "@mui/icons-material/RemoveCircleOutlineSharp";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Checkbox from "@mui/material/Checkbox";
import { grey } from "@mui/material/colors";

const Items = ({
  items,
  width,
  qtyChange,
  handleCheck,
  handleDelete,
  checkedItems,
}) => {
  const renderCheckbox = (item) => (
    <Checkbox
      sx={{
        color: grey[900],
        "&.Mui-checked": {
          color: grey[900],
        },
      }}
      className="py-1 hover:bg-zinc-50"
      onChange={() => handleCheck(item)}
      checked={
        checkedItems.find(
          (i) => i.id === item.id && i.size.size === item.size.size
        )
          ? true
          : false
      }
    />
  );
  return (
    <div className="w-full lg:w-2/3 divide-y">
      <div className="pb-5 flex justify-between">
        <p className="text-2xl font-medium">Ваши товары</p>
        <IconButton className="hover:text-red-500" onClick={handleDelete}>
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </div>
      {items.map((item) => (
        <div className="flex justify-between py-5" key={item.id}>
          <div className="flex gap-3 items-center w-full">
            <Link
              to={`/catalog/item/${item.id}`}
              className="bg-[#F9F9F9] aspect-square rounded-xl w-1/3 sm:w-1/5 md:w-1/6 flex items-center justify-center px-2"
            >
              <img alt="" src={item?.image} className="min-w-full" />
            </Link>
            <div className="w-full sm:flex sm:items-center sm:justify-between">
              <div className="flex items-start justify-between mb-3 sm:w-1/2">
                <div>
                  <Link to={`/catalog/item/${item.id}`}>{item?.name}</Link>
                  <p className="text-xs opacity-60">
                    Размер: {item?.size.size}
                  </p>
                </div>
                {width < 640 && <>{renderCheckbox(item)}</>}
              </div>
              <div className="flex items-center justify-between sm:gap-3 md:w-1/2">
                <div className="flex items-center">
                  <IconButton onClick={() => qtyChange(-1, item)}>
                    <RemoveCircleOutlineSharpIcon />
                  </IconButton>
                  {item?.qty}
                  <IconButton
                    onClick={() => qtyChange(+1, item)}
                    disabled={item.size.qty <= item.qty}
                  >
                    <AddCircleOutlineSharpIcon />
                  </IconButton>
                </div>
                <div>
                  {item.sale && (
                    <p className="relative text-sm text-zinc-400">
                      {item.size.old_price.toLocaleString("ru-RU")} ₽
                      <span className="absolute top-1/2 left-0 h-1 bg-zinc-400 rotate-[5deg] w-14" />
                    </p>
                  )}
                  <p className="whitespace-nowrap">
                    {item.size.price?.toLocaleString("ru-RU")} ₽
                  </p>
                </div>
                {width > 640 && <>{renderCheckbox(item)}</>}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Items;
