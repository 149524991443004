import { Map, Placemark } from "@pbe/react-yandex-maps";

const MapComponent = ({ containerWidth }) => {
  return (
    <Map
      defaultState={{ center: [55.779742, 37.590928], zoom: 14 }}
      width={containerWidth}
      height={600}
    >
      <Placemark geometry={[55.779742, 37.590928]} />
    </Map>
  );
};

export default MapComponent;
