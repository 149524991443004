const TelegramIcon = ({ className }) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    className={className}
  >
    <g id="4375108_logo_telegram_icon 1">
      <g id="_x33_35-telegram">
        <g id="Group">
          <g id="Group_2">
            <path
              id="Vector"
              d="M22.7198 4.60471L19.4658 19.9502C19.2204 21.0332 18.5801 21.3027 17.6703 20.7926L12.7125 17.1391L10.3203 19.44C10.0556 19.7047 9.83412 19.9262 9.32388 19.9262L9.68004 14.8767L18.869 6.57342C19.2685 6.21726 18.7824 6.01987 18.248 6.37607L6.88826 13.5289L1.99774 11.9983C0.933959 11.6661 0.91474 10.9344 2.21913 10.4242L21.3479 3.05479C22.2335 2.72268 23.0086 3.25209 22.7198 4.60471Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default TelegramIcon;
