import { useState } from "react";
import { useRouteLoaderData } from "react-router-dom";

import InfiniteScroll from "react-infinite-scroll-component";
import NorthEastOutlinedIcon from "@mui/icons-material/NorthEastOutlined";
import { CircularProgress } from "@mui/material";

import GridComponent from "../../Components/Grid/Grid";
import Item from "./Item";

import useViewport from "../../useViewport";
import { api, url } from "../../api";

export const News = () => {
  const { items, pagination, getItems } = useRouteLoaderData("news");
  const [data, setData] = useState(items);

  const { width } = useViewport();

  const handleLoadMore = async () => {
    const start = pagination.start + pagination.limit;
    const newData = await getItems(start);
    setData([...data, ...newData.items]);
  };

  const firstItem = data[0];
  const topRow = data.slice(1, 3);
  const bottomRow = data.slice(3);

  return (
    <InfiniteScroll
      dataLength={data.length}
      next={handleLoadMore}
      hasMore={data.length < pagination.total}
      loader={<CircularProgress color="inherit" className="mx-auto my-5" />}
      className="w-full my-5 uppercase max-w-[90rem] mx-auto"
    >
      <p className="text-4xl font-bold my-8">Новости</p>
      {data.length > 0 ? (
        <div className="mb-10">
          {width > 1024 ? (
            <div className="px-7">
              <GridComponent
                firstElemContent={
                  <span className="text-3xl">
                    <Item
                      item={firstItem}
                      arrow={<NorthEastOutlinedIcon className="h-8 w-8" />}
                    />
                  </span>
                }
                topRightElems={topRow}
                bottomElems={bottomRow}
                content={(item) => (
                  <span className="text-xl">
                    <Item item={item} arrow={<NorthEastOutlinedIcon />} />
                  </span>
                )}
              />
            </div>
          ) : (
            <>
              {items.map((item) => (
                <span className="text-xl sm:text-2xl md:text-3xl">
                  <Item item={item} arrow={<NorthEastOutlinedIcon />} />
                </span>
              ))}
            </>
          )}
        </div>
      ) : (
        <div className="mx-auto text-black/50 min-h-[40rem]">
          Никаких новостей
        </div>
      )}
    </InfiniteScroll>
  );
};

export const newsLoader = async () => {
  try {
    const getItems = async (start) => {
      const pageStart = start ? `&pagination[start]=${start}` : "";
      const res = await api.get(
        "/news?pagination[limit]=9&populate[0]=cover" + pageStart
      );
      const { pagination } = res.data.meta;
      const items = res.data.data.map((item) => {
        const { id, attributes } = item;
        const { cover, title } = attributes;
        return {
          id,
          title,
          cover: url + cover.data.attributes.url,
        };
      });
      return { items, pagination };
    };

    const { items, pagination } = await getItems();

    return { items, pagination, getItems };
  } catch (error) {
    console.log(error);
  }
};
