import { useLoaderData } from "react-router-dom";
import { api } from "../../api";

export const Policy = () => {
  const { data } = useLoaderData();

  return (
    <div className="flex flex-col py-10 px-3 lg:px-0 md:max-w-[50rem] mx-auto policy-container">
      <p className="text-2xl sm:text-3xl md:text-4xl font-medium uppercase mb-5 mx-auto">
        {data.title}
      </p>
      <p className="whitespace-pre-wrap">{data.text}</p>
    </div>
  );
};

export const policyLoader = async () => {
  try {
    const res = await api.get("/policy");
    const data = res.data.data.attributes;
    return { data };
  } catch (error) {
    console.log(error);
  }
};
