import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  handleClick() {
    window.location.reload();
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className="flex flex-col justify-center items-center h-screen">
          <img
            src="https://cdn-icons-png.flaticon.com/512/4743/4743087.png"
            alt="Картинка с грустным котиком"
          />
          <p className="text-xl my-5">Что-то пошло не так :(</p>
          <button onClick={this.handleClick}>Перезагрузить</button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
