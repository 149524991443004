import { useState } from "react";

import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { Button } from "@mui/material";

const FilterBtn = ({ filterElem, width }) => {
  const [filtersOpen, setFiltersOpen] = useState(false);

  const handleFilters = () => {
    if (filtersOpen) {
      if (width < 1024) {
        filterElem.current.classList.remove("w-full");
      } else if (width > 1024) {
        filterElem.current.classList.remove("w-1/2");
      }
      filterElem.current.classList.add("w-0");
      setTimeout(() => {
        filterElem.current.classList.add("hidden");
      }, 300);
      setFiltersOpen(false);
    } else {
      filterElem.current.classList.remove("hidden");
      setTimeout(() => {
        filterElem.current.classList.remove("w-0");
        if (width < 1024) {
          filterElem.current.classList.add("w-full");
        } else if (width > 1024) {
          filterElem.current.classList.add("w-1/2");
        }
      }, 100);
      setFiltersOpen(true);
    }
  };

  return (
    <Button
      className="text-black normal-case text-base flex gap-1 hover:text-red-500 hover:bg-zinc-50"
      onClick={handleFilters}
    >
      <p>{filtersOpen ? "Скрыть фильтры" : "Показать фильтры"}</p>
      <TuneOutlinedIcon />
    </Button>
  );
};

export default FilterBtn;
