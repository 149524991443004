import { Link, useMatches } from "react-router-dom";

const BreadcrumbsComponent = () => {
  const matches = useMatches();
  const crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => match.handle.crumb(match.data))
    .flat(2);

  return (
    <div className="text-lg">
      <Link to="/" className="opacity-40 hover:opacity-100">
        Главная
      </Link>
      {crumbs.slice(0, -1).map((item, idx) => (
        <span key={idx}>
          <span className="opacity-40">{" / "}</span>
          <Link to={item.url} className="opacity-40 hover:opacity-100">
            {item.name}
          </Link>
        </span>
      ))}
      {crumbs.slice(-1).map((item, idx) => (
        <span key={idx}>
          <span className="opacity-40">
            {" / "}
            {item.name}
          </span>
        </span>
      ))}
    </div>
  );
};

export default BreadcrumbsComponent;
