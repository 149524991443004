import { Button, Grid } from "@mui/material";

import CheckboxComponent from "../Checkbox/Checkbox";
import Collapse from "../Collapse/Collapse";

const Filters = ({
  filterElem,
  items,
  handleChecked,
  handleCheckBtn,
  checkedBtns,
}) => {
  const { brands, categories, genders, colors, size } = items;

  return (
    <div
      ref={filterElem}
      className="w-0 z-30 lg:z-0 absolute sm:static hidden bg-white divide-y overflow-x-hidden transition-all ease-out duration-300 pb-10"
    >
      <Collapse
        defaultExpanded={true}
        content={{
          label: "Бренд",
          children: brands.map((brand) => (
            <CheckboxComponent
              content={brand}
              filter={"[brands][name]"}
              handleChecked={handleChecked}
            />
          )),
        }}
      />
      <Collapse
        defaultExpanded={true}
        content={{
          label: "Пол",
          children: genders.map((gender) => (
            <CheckboxComponent
              content={gender}
              filter={"[genders][gender]"}
              handleChecked={handleChecked}
            />
          )),
        }}
      />
      <Collapse
        defaultExpanded={true}
        content={{
          label: "Категория",
          children: categories.map((categorie) => (
            <CheckboxComponent
              content={categorie}
              filter={"[categories][name]"}
              handleChecked={handleChecked}
            />
          )),
        }}
      />
      <Collapse
        defaultExpanded={true}
        content={{
          label: "Цвет",
          children: (
            <Grid container rowSpacing={2}>
              {colors.map((color) => (
                <Grid item xs={3} key={color.id}>
                  <Button
                    className="flex flex-col text-sm text-black normal-case hover:bg-zinc-50 w-full"
                    style={{
                      border:
                        checkedBtns.findIndex((i) => i === color.label) < 0
                          ? "0"
                          : "1px solid black",
                    }}
                    onClick={() =>
                      handleCheckBtn("[colors][label]", color.label)
                    }
                  >
                    <span
                      className="w-6 h-6 rounded-full mb-1"
                      style={{
                        background: color.value,
                        border: color.value === "#fff" && "1px solid black",
                      }}
                    ></span>
                    {color.label}
                  </Button>
                </Grid>
              ))}
            </Grid>
          ),
        }}
      />
      <Collapse
        defaultExpanded={true}
        content={{
          label: "Размер",
          children: (
            <Grid container rowSpacing={2} spacing={1}>
              {size.map((size) => (
                <Grid item xs={3} key={size}>
                  <Button
                    onClick={() => handleCheckBtn("[size][size]", size)}
                    className="w-full text-sm text-black border-zinc-300 hover:border-black hover:bg-zinc-50 h-full"
                    variant="outlined"
                    style={{
                      border:
                        checkedBtns.findIndex((i) => i === size) < 0
                          ? ""
                          : "1px solid black",
                    }}
                  >
                    {typeof size === "number" ? `EU ${size}` : size}
                  </Button>
                </Grid>
              ))}
            </Grid>
          ),
        }}
      />
    </div>
  );
};

export default Filters;
