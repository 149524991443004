import { Grid } from "@mui/material";

const GridComponent = ({
  firstElemContent,
  topRightElems,
  bottomElems,
  content,
}) => {
  return (
    <div>
      {firstElemContent && topRightElems && (
        <Grid container spacing={4} className="mb-14">
          <Grid item xs={8}>
            {firstElemContent}
          </Grid>
          <Grid container item xs={4} spacing={5} direction="column" wrap="nowrap">
            {topRightElems.map((item) => (
              <Grid item xs={6} key={item.id}>
                {content(item)}
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2} rowSpacing={4}>
        {bottomElems.map((item) => (
          <Grid item xs={4} key={item.id}>
            {content(item)}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default GridComponent;
