import React from "react";
import ReactDOM from "react-dom/client";

import { YMaps } from "@pbe/react-yandex-maps";
import { StyledEngineProvider } from "@mui/material/styles";
import bridge from "@vkontakte/vk-bridge";

import "./index.css";
import App from "./App";

bridge.send("VKWebAppInit");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <YMaps>
        <App />
      </YMaps>
    </StyledEngineProvider>
  </React.StrictMode>
);
