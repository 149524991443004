import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Collapse = ({ content, defaultExpanded }) => {
  return (
    <Accordion className="shadow-none" defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className="font-medium">
        {content.label}
      </AccordionSummary>
      <AccordionDetails className="py-0">{content.children}</AccordionDetails>
    </Accordion>
  );
};

export default Collapse;
