import { useEffect, useRef, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import { useSwipeable } from "react-swipeable";
import { Modal } from "@mui/material";

const Carousel = ({ images, item, favItems, handleFav, width }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [image, setImage] = useState("");

  const scrollContainer = useRef(null);

  useEffect(() => {
    if (scrollContainer.current) {
      const buttonWidth =
        scrollContainer.current.querySelector("button").offsetWidth;
      const scrollLeftValue = currentIndex * buttonWidth;
      scrollContainer.current.scrollLeft = scrollLeftValue;
    }
  }, [currentIndex]);

  const handleSlide = (idx) => {
    setCurrentIndex(idx);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      const idx = currentIndex;
      if (idx < images.length - 1) {
        setCurrentIndex(idx + 1);
      } else {
        setCurrentIndex(0);
      }
    },
    onSwipedRight: () => {
      const idx = currentIndex;
      if (idx === 0) {
        setCurrentIndex(images.length - 1);
      } else {
        setCurrentIndex(idx - 1);
      }
    },
  });

  const handleImageModalOpen = (url) => {
    setModalOpen(true);
    setImage(url);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setImage("");
  };

  return (
    <div className="w-full">
      <div
        className="flex flex-nowrap overflow-hidden relative lg:mb-5"
        {...handlers}
      >
        <div
          className={`flex justify-between absolute top-5 left-5 z-10 ${
            width < 1024 ? "w-[90%]" : "w-[95%]"
          }`}
        >
          <span
            className="rounded-full bg-red-500 text-white uppercase px-2"
            style={{
              visibility: item.new ? "visible" : "hidden",
            }}
          >
            New
          </span>
          <StarIcon
            style={{
              visibility:
                favItems?.findIndex((i) => i === item.id) < 0
                  ? "hidden"
                  : "visible",
            }}
            onClick={(e) => handleFav(e, item.id)}
            className="lg:hover:text-zinc-300 hover:cursor-pointer"
          />
        </div>
        {images.map((image) => (
          <div
            className="mb-5 lg:mb-0 min-w-full flex flex-col"
            key={image.id}
          >
            <button
              className="min-w-full aspect-square bg-[#F9F9F9] rounded-3xl flex items-center justify-center px-10 transition-transform duration-1000"
              style={{
                transform: `translate(-${currentIndex * 100}%)`,
              }}
              onClick={() => handleImageModalOpen(image.url)}
            >
              <img alt="" src={image.url} className="min-w-full" />
            </button>
          </div>
        ))}
      </div>
      <div
        className="flex overflow-x-scroll gap-5 flex-nowrap"
        ref={scrollContainer}
      >
        {images.map((image, idx) => (
          <button
            key={image.id}
            onClick={() => handleSlide(idx)}
            className="shrink-0 bg-[#F9F9F9] rounded-3xl flex items-center justify-center px-1 w-1/4 md:w-1/5 xl:w-1/6 aspect-square"
            style={{
              border: currentIndex === idx && "1px solid black",
            }}
          >
            <img alt="" src={image.url} />
          </button>
        ))}
      </div>
      <Modal open={modalOpen} onClose={handleModalClose}>
        <div
          style={{
            transform: "translate(-50%, -50%)",
          }}
          className="absolute top-1/2 left-1/2 w-full sm:w-max sm:h-1/2 overflow-y-scroll border-2 border-black bg-[#F9F9F9] flex p-10"
        >
          <img alt="" src={image} className="min-w-full" />
        </div>
      </Modal>
    </div>
  );
};

export default Carousel;
