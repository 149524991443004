import { useState } from "react";
import { Link } from "react-router-dom";

import { Drawer } from "@mui/material";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

import MenuComponent from "./MenuXL";
import MenuSM from "./MenuSM";
import Search from "./Search";

import useViewport from "../../useViewport";

import car from "../../Icons/car.svg";

const Header = ({ navItems, cartSize }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [menuhOpen, setMenuOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState("");
  const [subsubmenuOpen, setSubsubMenuOpen] = useState("");

  const { width } = useViewport();

  const showSubmenu = (e, menuItem) => {
    if (submenuOpen === menuItem) {
      setSubmenuOpen("");
      setSubsubMenuOpen("");
      setMenuOpen(false);
    } else {
      e.preventDefault();
      setSubmenuOpen(menuItem);
      setSubsubMenuOpen("");
    }
  };

  const showSubsubMenu = (e, menuItem) => {
    if (subsubmenuOpen === menuItem) {
      setSubmenuOpen("");
      setSubsubMenuOpen("");
      setMenuOpen(false);
    } else {
      e.preventDefault();
      setSubsubMenuOpen(menuItem);
    }
  };

  const toggleSearchDrawer = (event, open) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setSearchOpen(open);
  };

  const toggleMenuDrawer = (event, open) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setMenuOpen(open);
  };

  return (
    <header className="bg-[#F9F9F9] rounded-3xl px-3">
      <nav className="flex justify-between items-center">
        <div>
          <Link to="/" className="flex items-center gap-3 py-5">
            <img src={car} alt="" />
            {width > 500 && (
              <span className="text-3xl font-bold">HYPESTATION</span>
            )}
          </Link>
        </div>
        {width > 1024 && <MenuComponent items={navItems} />}
        <div className="flex gap-6 py-5">
          <Link to="/favorites">
            <StarBorderOutlinedIcon className="hover:text-red-500" />
          </Link>
          <button onClick={(e) => toggleSearchDrawer(e, true)}>
            <SearchOutlinedIcon className="hover:text-red-500" />
          </button>
          <Link to="/cart" className="relative">
            <ShoppingBagOutlinedIcon className="hover:text-red-500 relative" />
            {cartSize !== 0 && (
              <span className="rounded-full bg-red-500 py-1 px-2 text-white  w-1 h-4 absolute top-4 left-2">
                <span className="absolute top-0 left-1 text-xs">
                  {cartSize}
                </span>
              </span>
            )}
          </Link>
          {width < 1024 && (
            <button onClick={(e) => toggleMenuDrawer(e, true)}>
              <MenuOutlinedIcon />
            </button>
          )}
        </div>
      </nav>
      <Drawer
        anchor="right"
        open={menuhOpen}
        onClose={(e) => toggleMenuDrawer(e, false)}
      >
        <MenuSM
          items={navItems}
          toggleDrawer={toggleMenuDrawer}
          width={width}
          showSubmenu={showSubmenu}
          showSubsubMenu={showSubsubMenu}
          submenuOpen={submenuOpen}
          subsubmenuOpen={subsubmenuOpen}
          setMenuOpen={setMenuOpen}
        />
      </Drawer>
      <Drawer
        anchor="top"
        open={searchOpen}
        onClose={(e) => toggleSearchDrawer(false)}
      >
        <Search toggleDrawer={toggleSearchDrawer} width={width} />
      </Drawer>
    </header>
  );
};

export default Header;
