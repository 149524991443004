import { Link, useLoaderData, useRouteLoaderData } from "react-router-dom";
import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import NorthEastOutlinedIcon from "@mui/icons-material/NorthEastOutlined";
import { Helmet } from "react-helmet";

import useViewport from "../../useViewport";
import { api, url } from "../../api";
import ScrollContainer from "../../Components/ScrollContainer/ScrollContainer";

export const Article = () => {
  const { items } = useRouteLoaderData("news");
  const { item } = useLoaderData();

  const { width } = useViewport();

  const currentIndex = items?.findIndex((i) => i.id === item.id);
  const nextId = items[currentIndex + 1]?.id;
  const prevId = items[currentIndex - 1]?.id;

  const renderContent = (item) => (
    <Link
      to={`/news/article/${item.id}`}
      className="min-w-[50%] lg:min-w-[35%] lg:w-[35%] flex flex-col gap-3.5 pb-10 text-left text-xl font-medium stroke-2 uppercase hover:text-red-500 transition duration-300 ease-in-out lg:hover:-translate-y-1 lg:hover:scale-105"
      key={item.id}
    >
      <img src={item.cover} alt="" className="min-w-full rounded-3xl " />
      <p className="flex items-center gap-1">
        {item.title} <NorthEastOutlinedIcon />
      </p>
    </Link>
  );

  return (
    <div className="py-8">
      <Helmet>
        <title>{item.title}</title>
        <meta name="description" content={item.description} />
      </Helmet>
      <div className="flex justify-between items-start">
        <Link
          to={`/news/article/${prevId}`}
          className="flex hover:text-red-500"
          style={{
            visibility: currentIndex > 0 ? "visible" : "hidden",
          }}
        >
          <NavigateBeforeOutlinedIcon /> Назад
        </Link>
        {width > 1024 && (
          <p className="w-1/2 text-3xl text-center uppercase font-medium">
            {item.title}
          </p>
        )}
        <Link
          to={`/news/article/${nextId}`}
          className="flex hover:text-red-500"
          style={{
            visibility: currentIndex < items.length - 1 ? "visible" : "hidden",
          }}
        >
          Вперед <NavigateNextOutlinedIcon />
        </Link>
      </div>
      <div className="md:w-[50rem] mx-auto my-8">
        {width < 1024 && (
          <p className="text-xl sm:text-2xl md:text-3xl text-left uppercase font-medium mb-5">
            {item.title}
          </p>
        )}
        <img alt="" src={item.cover} className="min-w-full rounded-3xl mb-5" />
        <p className="whitespace-pre-wrap">{item.description}</p>
        <p className="mt-8 text-xl sm:text-2xl md:text-3xl font-medium">
          {item?.item_label}
        </p>
        <ul style={{ listStyleType: "disc" }} className="mt-3 md:mt-5 mb-5">
          {item.item_info &&
            Object.entries(item.item_info)?.map(([key, value]) => (
              <li key={key}>
                {key}: {value}
              </li>
            ))}
        </ul>
        <img alt="" src={item?.item_image} className="min-w-full rounded-3xl" />
      </div>
      <div className="my-16 lg:px-5 max-w-[90rem] mx-auto">
        {width < 1024 ? (
          <div className="flex flex-col">
            <p className="text-4xl uppercase font-bold mb-10">Читайте также</p>
            {items
              ?.filter((i) => i.id !== item.id)
              .slice(0, 3)
              .map((item) => renderContent(item))}
          </div>
        ) : (
          <>
            <p className="text-4xl uppercase font-bold mb-10">Читайте также</p>
            <ScrollContainer
              content={items
                ?.filter((i) => i.id !== item.id)
                .map((item) => renderContent(item))}
            />
          </>
        )}
      </div>
    </div>
  );
};

export const articleLoader = async ({ params }) => {
  try {
    const mapImage = (image) => url + image.data.attributes.url;

    const getItem = async () => {
      const res = await api.get(`/news/${params.id}?populate=*`);
      const { id, attributes } = res.data.data;
      const { cover, item_image, ...rest } = attributes;
      const item = {
        id,
        cover: mapImage(cover),
        item_image: mapImage(item_image),
        ...rest,
      };
      return item;
    };

    const item = await getItem();

    return { item, getItem };
  } catch (error) {
    console.log(error);
  }
};
