import { useState } from "react";
import { Button, Grid, Modal } from "@mui/material";

const Sizes = ({ sizes, width, handleSizeCheck, checkedSize, sizeChart }) => {
  const [tableModalOpen, setTableModalOpen] = useState(false);

  const toggleTableOpen = (open) => setTableModalOpen(open);

  return (
    <div>
      <div className="flex justify-between mb-3">
        <p>Выберите размер</p>
        {sizeChart && (
          <button
            className="opacity-60 hover:opacity-100"
            onClick={() => toggleTableOpen(true)}
          >
            Таблица размеров
          </button>
        )}
      </div>
      <Grid container spacing={2}>
        {sizes.map((size) => (
          <Grid item xs={width < 660 ? 3 : 2} key={size.size}>
            <Button
              className="w-full py-5 lg:py-3 h-full flex flex-col text-sm rounded-xl text-black border-zinc-300 hover:border-black hover:bg-zinc-50"
              variant="outlined"
              onClick={() => handleSizeCheck(size)}
              style={{
                border: checkedSize === size ? "1px solid black" : "",
              }}
            >
              <span>
                {size.item === "shoes" ? `EU ${size.size}` : size.size}
              </span>
            </Button>
          </Grid>
        ))}
      </Grid>
      <Modal open={tableModalOpen} onClose={() => toggleTableOpen(false)}>
        <div
          style={{
            transform: "translate(-50%, -50%)",
          }}
          className="absolute top-1/2 left-1/2 w-full sm:w-max sm:h-1/2 overflow-y-scroll border-2 border-black"
        >
          <img alt="" src={sizeChart} />
        </div>
      </Modal>
    </div>
  );
};

export default Sizes;
