import { Link } from "react-router-dom";

const Menu = ({ items }) => {
  return (
    <div className="flex gap-5 xl:gap-10 text-sm items-center">
      {items.map((item) => (
        <div key={item.id}>
          {item.children.length > 0 ? (
            <div className="py-5 relative menu-item">
              <Link to={item.url} className="hover:text-red-500 uppercase">
                {item.title}
              </Link>
              <div
                className={`absolute top-12 bg-[#F9F9F9] rounded-3xl submenu z-20 min-w-[11rem]`}
              >
                <div className="flex flex-col pl-5 pr-18 py-4 w-44">
                  {item.children.map((child) => (
                    <div key={child.id}>
                      {child.children.length > 0 ? (
                        <div className="submenu-item">
                          <Link to={child.url} className="hover:text-red-500">
                            {child.title}
                          </Link>
                          <div
                            className={`absolute top-0 left-40 px-5 py-4 bg-white rounded-3xl min-w-[11rem] flex flex-col subsubmenu z-20`}
                          >
                            {child.children.map((sub) => (
                              <Link
                                to={sub.url}
                                className="hover:text-red-500"
                                key={sub.id}
                              >
                                {sub.title}
                              </Link>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <Link to={child.url} className="hover:text-red-500">
                          {child.title}
                        </Link>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <Link to={item.url} className="hover:text-red-500 py-5 uppercase">
              {item.title}
            </Link>
          )}
        </div>
      ))}
    </div>
  );
};

export default Menu;
