import { Link } from "react-router-dom";

const Navigation = ({ items }) => {
  return (
    <nav className="flex flex-col lg:flex-row lg:gap-5 xl:gap-16 gap-5 text-sm py-2">
      {items.map((item) => (
        <div key={item.id}>
          {item.children.length > 0 ? (
            <div className="flex flex-col gap-4">
              <Link to={item.url} className="hover:text-red-500 uppercase">
                {item.title}
              </Link>
              {item.children.map((child) => (
                <Link
                  to={child.url}
                  className="hover:opacity-100 text-xs opacity-40"
                  key={child.id}
                >
                  {child.title}
                </Link>
              ))}
            </div>
          ) : (
            <Link to={item.url} className="hover:text-red-500 uppercase">
              {item.title}
            </Link>
          )}
        </div>
      ))}
    </nav>
  );
};

export default Navigation;
