import { Link } from "react-router-dom";

const Brands = ({ items }) => {
  return (
    <div className="my-16 lg:px-5">
      <div className="flex justify-between uppercase font-bold w-full mb-5">
        <p className="text-4xl">Бренды</p>
      </div>
      <div className="flex flex-col lg:flex-row lg:flex-wrap w-full justify-between gap-3.5">
        {items.map((item) => (
          <Link
            to={`/catalog/brands/${item.name}`}
            className="lg:w-[49%] transition duration-300 ease-in-out lg:hover:-translate-y-1 lg:hover:scale-105 hover:z-30"
            key={item.id}
          >
            <img
              alt=""
              src={item?.cover}
              className="w-full h-full object-cover rounded-3xl"
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Brands;
