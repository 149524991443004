import { Button, Input, FormControl, RadioGroup } from "@mui/material";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";

import RadioComponent from "../../Components/Radio/Radio";

const Order = ({
  total,
  delivery,
  setDelivery,
  payment,
  setPayment,
  formData,
  handleFormDataChange,
  handleOrder,
  destination,
  setDestination,
  handleMoscow,
}) => {
  const { name, phone, email } = formData;

  const totalWithDelivery =
    total + (destination === "moscow" ? 1200 : destination === "mo" ? 2500 : 0);

  const renderInput = (placeholder, label) => (
    <Input
      className="bg-[#F5F5F5] rounded-3xl py-2 px-3 w-full"
      disableUnderline
      placeholder={placeholder}
      onChange={(e) => handleFormDataChange(label, e.target.value)}
      value={formData[label]}
    />
  );

  return (
    <div className="w-full lg:w-1/3">
      <p className="text-2xl font-medium mb-5">Оформить заказ</p>
      <div className="flex flex-col gap-5 mb-10">
        {renderInput("ФИО *", "name")}
        {renderInput("Телефон *", "phone")}
        {renderInput("Email *", "email")}
        <div className="flex gap-2 sm:gap-5 lg:gap-2 xl:gap-5">
          <Button
            className="flex gap-2 sm:gap-5 lg:gap-2 xl:gap-5 text-xl normal-case w-1/2 h-28 rounded-3xl text-black font-medium border-zinc-300 hover:border-black hover:bg-zinc-50"
            variant="outlined"
            onClick={() => setDelivery("Доставка")}
            style={{
              border: delivery === "Доставка" ? "1px solid black" : "",
            }}
          >
            Доставка
            <LocalShippingOutlinedIcon />
          </Button>
          <Button
            className="flex gap-2 sm:gap-5 lg:gap-2 xl:gap-5 text-xl normal-case w-1/2 h-28 rounded-3xl text-black font-medium border-zinc-300 hover:border-black hover:bg-zinc-50"
            variant="outlined"
            onClick={() => setDelivery("Самовывоз")}
            style={{
              border: delivery === "Самовывоз" ? "1px solid black" : "",
            }}
          >
            Самовывоз
            <StoreOutlinedIcon />
          </Button>
        </div>
        {delivery === "Доставка" ? (
          <>
            <FormControl>
              <RadioGroup>
                <RadioComponent
                  label="Москва"
                  value="moscow"
                  handleChange={handleMoscow}
                />
                <RadioComponent
                  label="МО"
                  value="mo"
                  handleChange={() => setDestination("mo")}
                />
                <RadioComponent
                  label="СДЭК по России"
                  value="sdek"
                  handleChange={() => setDestination("sdek")}
                />
              </RadioGroup>
            </FormControl>
            {destination === "sdek" && (
              <p className="text-black/50">
                Сумма доставки расчитывается согласно тарифам СДЭК
              </p>
            )}
            {destination !== "moscow" && renderInput("Город", "city")}
            {renderInput("Улица", "street")}
            <div className="flex gap-5">
              {renderInput("Дом", "aptNum")}
              {renderInput("Квартира / офис", "apt")}
            </div>
          </>
        ) : (
          delivery === "Самовывоз" && (
            <p className="text-black/50">
              <p>Самовывоз возможен из нашего магазина по адресу:</p>
              <p>ул. Лесная 20 стр. 6</p>
              <p>По будням с 10 до 22 и по выходным с 12 до 18</p>
            </p>
          )
        )}
      </div>
      <p className="text-xl font-semibold mb-3">Способ оплаты</p>
      <FormControl>
        <RadioGroup>
          <RadioComponent
            label="Наличными при получении"
            value="cash"
            handleChange={() => setPayment("Наличными")}
            disabled={delivery !== "Самовывоз"}
          />
          <RadioComponent
            label="Картой онлайн"
            value="card"
            handleChange={() => setPayment("Картой")}
          />
        </RadioGroup>
      </FormControl>
      <p className="text-xl font-semibold my-5">
        Итоговая сумма:{" "}
        <span className="font-normal">
          {totalWithDelivery?.toLocaleString("ru-RU")} ₽
        </span>
      </p>
      <button
        className={
          delivery && payment && name && phone && email
            ? "my-5 w-full text-white bg-gradient-to-r from-[#F5201F] to-[#FF4E16] rounded-3xl px-10 py-3 transition duration-300 ease-in-out lg:hover:-translate-y-1 lg:hover:scale-110"
            : "my-5 w-full bg-[#F9F9F9] text-black/50 rounded-3xl px-10 py-3 hover:cursor-not-allowed"
        }
        onClick={handleOrder}
      >
        <span className="uppercase text-lg font-bold ">Оформить заказ</span>
      </button>
    </div>
  );
};

export default Order;
