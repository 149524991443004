import { Link } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import { useSwipeable } from "react-swipeable";

import useViewport from "../../useViewport";
import { useState } from "react";

const Card = ({ item, cardWidth, fontSize, handleFav, favItems, path }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const { width } = useViewport();

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      const idx = currentIndex;
      if (idx < item.images.length - 1) {
        setCurrentIndex(idx + 1);
      } else {
        setCurrentIndex(0);
      }
    },
    onSwipedRight: () => {
      const idx = currentIndex;
      if (idx === 0) {
        setCurrentIndex(item.images.length - 1);
      } else {
        setCurrentIndex(idx - 1);
      }
    },
  });

  return (
    <Link
      to={path}
      className="mb-5 lg:mb-0 min-w-full flex flex-col group relative transition duration-300 ease-in-out lg:hover:-translate-y-1 lg:hover:scale-105"
    >
      <div
        className="flex justify-between absolute top-5 left-5 w-[90%] z-30"
        style={{
          width: cardWidth && cardWidth,
        }}
      >
        <span
          className="rounded-full bg-red-500 text-white uppercase px-2"
          style={{
            visibility: item.new ? "visible" : "hidden",
          }}
        >
          New
        </span>
        <StarIcon
          className={
            favItems?.findIndex((i) => i === item.id) < 0
              ? `text-zinc-300 lg:group-hover:opacity-100 lg:hover:text-zinc-950 ${
                  width < 1024 ? "opacity-50" : "opacity-0"
                }`
              : "lg:hover:text-zinc-300"
          }
          onClick={(e) => handleFav(e, item.id)}
        />
      </div>
      <div
        className="flex flex-nowrap overflow-hidden lg:mb-5"
        {...handlers}
      >
        {item.images.map((image) => (
          <div
            key={image.id}
            className="min-w-full mb-5 aspect-square bg-[#F9F9F9] rounded-3xl flex items-center justify-center px-5 transition-transform duration-1000"
            style={{
              transform: `translate(-${currentIndex * 100}%)`,
            }}
          >
            <img alt="" src={image.url} className="min-w-full" />
          </div>
        ))}
      </div>
      <p
        className="text-sm mb-2"
        style={{
          fontSize: fontSize && fontSize,
        }}
      >
        {item.name}
      </p>
      <div
        className="flex gap-3"
        style={{
          marginTop: fontSize ? "1rem" : "",
        }}
      >
        {item.sale && (
          <p
            className="relative text-sm font-bold text-zinc-400"
            style={{
              fontSize: fontSize && fontSize,
            }}
          >
            {item.old_price.toLocaleString("ru-RU")} ₽
            <span
              className="absolute top-1/2 left-0 h-1 bg-zinc-400 rotate-[5deg] w-14"
              style={{
                width: fontSize ? "8rem" : "",
              }}
            />
          </p>
        )}
        <p
          className="text-sm font-bold"
          style={{
            fontSize: fontSize && fontSize,
          }}
        >
          {item.price.toLocaleString("ru-RU")} ₽
        </p>
      </div>
    </Link>
  );
};
export default Card;
