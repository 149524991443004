import { useLoaderData, useOutletContext } from "react-router-dom";

import Contacts from "./Contacts";
import Carousel from "../../Components/Carousel/Carousel";
import New from "./New";
import Brands from "./Brands";
import Collections from "./Collections";
import News from "./News";

import { api, url } from "../../api";

import useViewport from "../../useViewport";

export const Home = () => {
  const { carouselData, newItems, brandItems, collectionItems, newsItems } =
    useLoaderData();
  const { contacts } = useOutletContext();
  const { tel, email, address, whatsapp, telegram } = contacts;

  const { width } = useViewport();

  const contactsInfo = [
    {
      label: "Адрес",
      data: address,
    },
    {
      label: "Телефон",
      data: tel,
    },
    {
      label: "Почта",
      data: email,
    },
  ];

  const contactsBtns = [
    {
      label: "WhatsApp",
      href: `//api.whatsapp.com/send?phone=${whatsapp}`,
    },
    {
      label: "Telegram",
      href: `https://t.me/${telegram}`,
    },
  ];

  return (
    <div>
      <Carousel data={carouselData} width={width} />
      <div className="max-w-[90rem] mx-auto">
        <New items={newItems} />
        <Brands items={brandItems} />
        <Collections items={collectionItems} width={width} />
        <News items={newsItems} width={width} />
        <Contacts info={contactsInfo} btns={contactsBtns} />
      </div>
    </div>
  );
};

export const homeLoader = async () => {
  try {
    const p1 = api.get("/carousels?populate=*");
    const p2 = api.get(
      "/items?pagination[limit]=9&filters[new][$eq]=true&populate[0]=images"
    );
    const p3 = api.get(
      "/brands?pagination[limit]=4&filters[collection][$eq]=false&filters[home][$eq]=true&populate[0]=cover"
    );
    const p4 = api.get(
      "/brands?pagination[limit]=5&filters[collection][$eq]=true&filters[home][$eq]=true&populate[0]=cover"
    );
    const p5 = api.get("/news?pagination[limit]=9&populate[0]=cover");

    const [r1, r2, r3, r4, r5] = await Promise.all([p1, p2, p3, p4, p5]);

    const mapItemData = (item, mapping) => {
      const { id, attributes } = item;
      return {
        id,
        ...mapping(attributes),
      };
    };

    const carouselData = r1.data.data.map((i) =>
      mapItemData(i, (attributes) => {
        const { image, item, news, ...rest } = attributes;
        return {
          image: url + image.data.attributes.url,
          item: item.data ? item.data.id : "",
          news: news.data ? news.data.id : "",
          ...rest,
        };
      })
    );

    const newItems = r2.data.data.map((item) =>
      mapItemData(item, (attributes) => {
        const { images, ...rest } = attributes;
        return {
          image: url + images.data[0].attributes.url,
          ...rest,
        };
      })
    );

    const mapCoverItem = (item) => ({
      cover: url + item.cover.data.attributes.url,
    });

    const brandItems = r3.data.data.map((item) =>
      mapItemData(item, (attributes) => ({
        name: attributes.name,
        ...mapCoverItem(attributes),
      }))
    );

    const collectionItems = r4.data.data.map((item) =>
      mapItemData(item, (attributes) => ({
        name: attributes.name,
        ...mapCoverItem(attributes),
      }))
    );

    const newsItems = r5.data.data.map((item) =>
      mapItemData(item, (attributes) => ({
        title: attributes.title,
        ...mapCoverItem(attributes),
      }))
    );

    return { carouselData, newItems, brandItems, collectionItems, newsItems };
  } catch (error) {
    console.log(error);
  }
};
