import { useCallback, useEffect, useRef, useState } from "react";
import { useLoaderData, useLocation, useMatches } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import { CircularProgress } from "@mui/material";

import Header from "../../Components/CatalogHeader/Header";
import Filters from "../../Components/Filters/Filters";
import FilterBtn from "../../Components/Filters/FilterBtn";
import BreadcrumbsComponent from "../../Components/Breadcrumbs/Breadcrumbs";
import GridComponent from "../../Components/Grid/Grid";
import Card from "../../Components/Card/Card";

import useViewport from "../../useViewport";
import { api, url } from "../../api";

export const Catalog = ({ favItems, handleFav }) => {
  const { items, pagination, filters, getItems } = useLoaderData();

  const [data, setData] = useState(items);
  const [pageInfo, setPageInfo] = useState(pagination);
  const [filterQuery, setQuery] = useState("");
  const [checkedFilterBtns, setCheckedBtns] = useState([]);
  const [sortType, setSortType] = useState("");
  const [sort, setSort] = useState("");

  const { width } = useViewport();

  const filterElem = useRef(null);

  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);
  let matches = useMatches();

  const crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => match.handle.crumb(match.data))
    .flat(2);

  const getNewData = useCallback(
    async (start, filters, sorts) => {
      const newData = await getItems(start, filters, sorts);
      setData(newData.items);
      setPageInfo(newData.pagination);
    },
    [getItems]
  );

  const clearState = () => {
    setQuery("");
    setCheckedBtns([]);
    setSortType("");
    setSort("");
  };

  useEffect(() => {
    if (location.pathname !== pathname) {
      setPathname(location.pathname);
      clearState();
      getNewData();
    }
  }, [getItems, getNewData, items, location.pathname, pathname]);

  const handleLoadMore = async () => {
    const start = data.length;
    const newData = await getItems(start, filterQuery, sort);
    setData([...data, ...newData.items]);
  };

  const handleCheckFilters = async (newQuery) => {
    let filters = filterQuery;

    if (filterQuery.includes(newQuery)) {
      filters = filterQuery.replace(newQuery, "");
    } else {
      filters = filterQuery + newQuery;
    }

    setQuery(filters);

    await getNewData(0, filters, sort);
  };

  const handleCheckFilterBtns = async (filter, value) => {
    console.log(typeof value);
    setCheckedBtns((prevCheckedBtns) => {
      const index = prevCheckedBtns.indexOf(value);
      if (index < 0) {
        return [...prevCheckedBtns, value];
      } else {
        return prevCheckedBtns.filter((i) => i !== value);
      }
    });

    let newQuery = "";

    if (filter === "[size][size]") {
      if (typeof value === "number") {
        newQuery = `&filters${filter}[$contains]="EU"&filters${filter}[$notContains]="${value}":0`;
      } else {
        newQuery = `&filters${filter}[$contains]="other"&filters${filter}[$notContains]="${value}":0`;
      }
    } else {
      newQuery = `&filters${filter}[$eq]=${value}`;
    }
    await handleCheckFilters(newQuery);
  };

  const handleSort = async (type) => {
    let sorts;
    if (sortType === type) {
      setSortType("");
      setSort("");
    } else {
      setSortType(type);
      if (type === "asc" || type === "desc") {
        sorts = `&sort[0]=price:${type}`;
      } else if (type === "new" || type === "popular") {
        sorts = `&sort[0]=${type}:desc`;
      }
      setSort(sorts);
    }

    await getNewData(0, filterQuery, sorts);
  };

  const firstItem = data[0];
  const topRow = data.slice(1, 3);
  const bottomRow = data.slice(3);

  return (
    <div className="py-8 max-w-[90rem] mx-auto px-1 sm:px-3 lg:px-5 overflow-x-hidden">
      <Header
        filterBtn={<FilterBtn filterElem={filterElem} width={width} />}
        breadcrumbs={<BreadcrumbsComponent />}
        title={crumbs[crumbs.length - 1].name}
        sort={handleSort}
        sortType={sortType}
        width={width}
      />
      <InfiniteScroll
        dataLength={data.length}
        next={handleLoadMore}
        hasMore={data.length < pageInfo.total}
        loader={<CircularProgress color="inherit" className="my-5" />}
        className="flex flex-col items-center justify-center"
      >
        <div className="flex">
          <Filters
            filterElem={filterElem}
            items={filters}
            handleChecked={handleCheckFilters}
            handleCheckBtn={handleCheckFilterBtns}
            checkedBtns={checkedFilterBtns}
          />
          {data.length <= 0 ? (
            <div className="w-full text-center text-black/50">
              Ничего не нашлось :(
            </div>
          ) : (
            <div className="w-full lg:p-7">
              {width > 1024 ? (
                <GridComponent
                  firstElemContent={
                    <Card
                      item={firstItem}
                      cardWidth="95%"
                      fontSize="1.875rem"
                      handleFav={handleFav}
                      favItems={favItems}
                      path={
                        location.pathname.split("/catalog/")[1]
                          ? `/catalog/${
                              location.pathname.split("/catalog/")[1] +
                              "/item/" +
                              firstItem.id
                            }`
                          : `/catalog/item/${firstItem.id}`
                      }
                    />
                  }
                  topRightElems={topRow}
                  bottomElems={bottomRow}
                  content={(item) => (
                    <Card
                      item={item}
                      handleFav={handleFav}
                      favItems={favItems}
                      path={
                        location.pathname.split("/catalog/")[1]
                          ? `/catalog/${
                              location.pathname.split("/catalog/")[1] +
                              "/item/" +
                              item.id
                            }`
                          : `/catalog/item/${item.id}`
                      }
                    />
                  )}
                />
              ) : (
                <>
                  {data.map((item) => (
                    <div className="mb-10" key={item.id}>
                      <Card
                        item={item}
                        handleFav={handleFav}
                        favItems={favItems}
                        path={
                          location.pathname.split("/catalog/")[1]
                            ? `/catalog/${
                                location.pathname.split("/catalog/")[1] +
                                "/item/" +
                                item.id
                              }`
                            : `/catalog/item/${item.id}`
                        }
                      />
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export const catalogLoader = async ({ request, params }) => {
  try {
    const brandFilter = `&filters[brands][name][$eq]=${params.brand}`;
    const newCatalog = request.url.includes("newitems")
      ? "&filters[new][$eq]=true"
      : "";
    const collectionCatalog = request.url.includes("collections")
      ? `&filters[brands][collection][$eq]=true${
          params.collection
            ? `&filters[brands][name][$eq]=${params.collection}`
            : ""
        }`
      : "";
    const saleCatalog = request.url.includes("sale")
      ? "&filters[sale][$eq]=true"
      : "";
    const brandsCatalog = request.url.includes("brands") ? brandFilter : "";
    const categoryCatalog = request.url.includes("categories")
      ? `&filters[categories][value][$eqi]=${params.category}${
          params.brand ? brandFilter : ""
        }`
      : "";

    const searchCatalog = request.url.includes("search")
      ? `&filters[name][$contains]=${params.query}`
      : "";

    const getItems = async (start, filters, sort) => {
      const pageStart = start ? `&pagination[start]=${start}` : "";
      const filter = filters || "";
      const sorter = sort || "";
      const res = await api.get(
        "/items?pagination[limit]=9&populate[0]=images&populate[1]=size" +
          newCatalog +
          collectionCatalog +
          saleCatalog +
          brandsCatalog +
          categoryCatalog +
          searchCatalog +
          pageStart +
          filter +
          sorter
      );

      const { pagination } = res.data.meta;
      const items = res.data.data.map((item) => {
        const { id, attributes } = item;
        const { createdAt, updatedAt, publishedAt, images, size, ...rest } =
          attributes;
        return {
          id,
          images: images.data.map((image) => ({
            id: image.id,
            url: url + image.attributes.url,
          })),
          size: size.data,
          ...rest,
        };
      });

      return { items, pagination };
    };

    const { items, pagination } = await getItems();
    const r2 = await api.get("/filter?populate=*");

    const mapFilterItems = (data, mapFunction) =>
      data.map((item) => {
        const { id, attributes } = item;
        return mapFunction(id, attributes);
      });

    const filters = {
      brands:
        params.brand || params.collection
          ? [{ name: params.brand }] || [{ name: params.collection }]
          : mapFilterItems(
              r2.data.data.attributes.brands.data,
              (id, attributes) => ({
                id,
                name: attributes.name,
              })
            ),
      categories: mapFilterItems(
        r2.data.data.attributes.categories.data,
        (id, attributes) => ({
          id,
          name: attributes.name,
        })
      ),
      colors: mapFilterItems(
        r2.data.data.attributes.colors.data,
        (id, attributes) => ({
          id,
          label: attributes.label,
          value: attributes.value,
        })
      ),
      genders: mapFilterItems(
        r2.data.data.attributes.genders.data,
        (id, attributes) => ({
          id,
          name: attributes.gender,
        })
      ),
      size: r2.data.data.attributes.size.EU,
    };

    return {
      items,
      pagination,
      filters,
      getItems,
    };
  } catch (error) {
    console.error(error);
  }
};
