const WhatsappIcon = ({ className }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="843786_whatsapp_icon 1">
      <g id="Group">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6344 4.5355C17.6654 2.56425 15.0469 1.47819 12.2571 1.47705C6.50889 1.47705 1.83043 6.15513 1.82818 11.9051C1.82738 13.7432 2.30761 15.5373 3.22027 17.1189L1.74072 22.523L7.26924 21.0728C8.79247 21.9036 10.5075 22.3416 12.253 22.3421H12.2572C12.2569 22.3421 12.2575 22.3421 12.2572 22.3421C18.0048 22.3421 22.6837 17.6636 22.6861 11.9135C22.6871 9.12684 21.6033 6.50667 19.6344 4.5355ZM12.2572 20.5809H12.2536C10.6983 20.5803 9.17275 20.1624 7.84188 19.3726L7.52539 19.1848L4.24469 20.0454L5.1204 16.8467L4.91428 16.5187C4.04662 15.1387 3.58836 13.5436 3.58904 11.9057C3.5909 7.12655 7.47946 3.23841 12.2607 3.23841C14.5759 3.23913 16.7523 4.14197 18.3888 5.7804C20.0254 7.41882 20.9261 9.59666 20.9252 11.9128C20.9232 16.6923 17.0348 20.5809 12.2572 20.5809Z"
        />
        <path
          id="Vector_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0118 14.0892C16.7512 13.9587 15.47 13.3284 15.2312 13.2414C14.9924 13.1544 14.8186 13.111 14.6449 13.3718C14.4712 13.6326 13.9718 14.2196 13.8198 14.3936C13.6678 14.5674 13.5158 14.5893 13.2553 14.4587C12.9947 14.3283 12.1551 14.0532 11.1598 13.1654C10.3851 12.4744 9.86211 11.6211 9.71005 11.3602C9.55807 11.0994 9.69388 10.9584 9.82435 10.8284C9.94157 10.7116 10.085 10.5241 10.2153 10.3719C10.3455 10.2198 10.3889 10.111 10.4758 9.9372C10.5627 9.76325 10.5193 9.61106 10.4541 9.48068C10.3889 9.35025 9.86783 8.06763 9.6507 7.54579C9.43912 7.03775 9.22432 7.10658 9.06434 7.0985C8.91258 7.09092 8.73863 7.08936 8.56494 7.08936C8.39124 7.08936 8.10888 7.15459 7.87008 7.41541C7.63123 7.67631 6.95801 8.30678 6.95801 9.58926C6.95801 10.8719 7.89175 12.111 8.02205 12.2849C8.15231 12.4589 9.85953 15.0908 12.4735 16.2195C13.0952 16.4881 13.5806 16.6484 13.959 16.7684C14.5833 16.9668 15.1514 16.9388 15.6003 16.8717C16.101 16.7969 17.1421 16.2413 17.3592 15.6327C17.5764 15.024 17.5764 14.5022 17.5112 14.3935C17.4461 14.2849 17.2723 14.2196 17.0118 14.0892Z"
        />
      </g>
    </g>
  </svg>
);

export default WhatsappIcon;
