import { useLocation } from "react-router-dom";
import Card from "../../Components/Card/Card";
import ScrollContainer from "../../Components/ScrollContainer/ScrollContainer";

const Recommendations = ({ items, handleFav, favItems }) => {
  const location = useLocation();

  return (
    <div className="my-10">
      <p className="text-4xl font-bold uppercase">Рекомендуемые товары</p>
      <ScrollContainer
        content={items.map((item) => (
          <div
            className="pb-10 min-w-full w-full sm:min-w-[80%] sm:w-[80%] md:min-w-[60%] md:w-[60%] lg:min-w-[40%] lg:w-[40%] xl:min-w-[30%]"
            key={item.id}
          >
            {
              <Card
                item={item}
                handleFav={handleFav}
                favItems={favItems}
                path={`${location.pathname.split("/item/")[0]}/item/${item.id}`}
              />
            }
          </div>
        ))}
      />
    </div>
  );
};

export default Recommendations;
