import { Link } from "react-router-dom";
import NorthEastOutlinedIcon from "@mui/icons-material/NorthEastOutlined";

import ScrollContainer from "../../Components/ScrollContainer/ScrollContainer";

const News = ({ items, width }) => {
  const renderContent = (item) => (
    <Link
      to={`/news/article/${item.id}`}
      className="min-w-[50%] lg:min-w-[35%] lg:w-[35%] flex flex-col gap-3.5 pb-10 text-left text-xl font-medium uppercase hover:text-red-500 transition duration-300 ease-in-out lg:hover:-translate-y-1 lg:hover:scale-105"
      key={item.id}
    >
      <img src={item.cover} alt="" className="min-w-full rounded-3xl " />
      <p className="flex items-center gap-1">
        {item.title} <NorthEastOutlinedIcon />
      </p>
    </Link>
  );

  return (
    <div className="my-16 lg:px-5">
      <div className="flex flex-col lg:flex-row lg:justify-between gap-2 uppercase font-bold w-full mb-5">
        <p className="text-4xl">Новости</p>
        <div className="flex text-xl stroke-2 items-center gap-1 hover:text-red-500">
          <Link to="/news">Посмотреть все</Link>
          <NorthEastOutlinedIcon />
        </div>
      </div>
      {width < 1024 ? (
        <div className="flex flex-col">
          {items.slice(0, 3).map((item) => renderContent(item))}
        </div>
      ) : (
        <ScrollContainer content={items.map((item) => renderContent(item))} />
      )}
    </div>
  );
};

export default News;
