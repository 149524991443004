import axios from "axios";

export const url = `https://${process.env.REACT_APP_IP}`;

export const api = axios.create({
  baseURL: `${url}/api`,
  withCredentials: true,
  headers: {
    Authorization: `bearer ${process.env.REACT_APP_KEY}`,
  },
});
