import car from "../../Icons/car.svg";

const Loading = () => {
  // TODO animation
  return (
    <div className="h-screen flex justify-center items-center">
      <div className="static">
        <img src={car} className="relative top-4 icon" alt="" />
      </div>
    </div>
  );
};

export default Loading;
