import { useLoaderData } from "react-router-dom";
import { api } from "../../api";
import Markdown from "react-markdown";

export const Agreement = () => {
  const { data } = useLoaderData();

  console.log(data);

  return (
    <div className="flex flex-col gap-10 py-10 px-3 lg:px-0 md:max-w-[50rem] mx-auto policy-container">
      <div className="flex flex-col gap-3">
        <p className="text-xl md:text-2xl font-medium uppercase mx-auto">
          {data.title1}
        </p>
        <p className="whitespace-pre-wrap">
          <Markdown>{data.text1}</Markdown>
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <p className="text-xl md:text-2xl font-medium uppercase mx-auto">
          {data.title2}
        </p>
        <p className="whitespace-pre-wrap">
          <Markdown>{data.text2}</Markdown>
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <p className="text-xl md:text-2xl font-medium uppercase mx-auto">
          {data.title3}
        </p>
        <p className="whitespace-pre-wrap">
          <Markdown>{data.text3}</Markdown>
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <p className="text-xl md:text-2xl font-medium uppercase mx-auto">
          {data.title4}
        </p>
        <p className="whitespace-pre-wrap">
          <Markdown>{data.text4}</Markdown>
        </p>
      </div>
    </div>
  );
};

export const agreementLoader = async () => {
  try {
    const res = await api.get("/agreement");
    const data = res.data.data.attributes;
    return { data };
  } catch (error) {
    console.log(error);
  }
};
