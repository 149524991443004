import Collapse from "../../Components/Collapse/Collapse";

const Description = ({ description, info }) => {
  return (
    <div className="divide-y">
      <Collapse
        defaultExpanded={true}
        content={{
          label: "Описание",
          children: (
            <p className="text-sm whitespace-pre-wrap">{description}</p>
          ),
        }}
      />
      <Collapse
        content={{
          label: "Доставка и оплата",
          children: (
            <p className="text-sm whitespace-pre-wrap">{info?.delivery}</p>
          ),
        }}
      />
      <Collapse
        content={{
          label: "Обмен и возврат",
          children: (
            <p className="text-sm whitespace-pre-wrap">{info?.return}</p>
          ),
        }}
      />
    </div>
  );
};

export default Description;
